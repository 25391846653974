import logo from './logo.svg';
import React, {Component} from 'react';
import { BrowserRouter, Switch, Route, NavLink } from 'react-router-dom';
import { Loader, Dimmer, Progress, Input, Button } from 'semantic-ui-react';

import Home from './components/Home.js';
import Entry from './components/Entry.js';
import WordBuilder from './components/WordBuilder.js';
import SentenceBuilder from './components/SentenceBuilder.js';
import Dialogues from './components/Dialogues.js';
import Report from './components/Report.js';
import TlingitMap from './components/Map.js';
import Phrasebook from './components/Phrasebook.js';
import Conversations from './components/Conversations.js';
import Chinese from './components/constants/chinese.js';
import 'semantic-ui-css/semantic.min.css'
import './App.css';
import axios from 'axios';

// export const API_URL = "https://yugtun-api.herokuapp.com";
// export const API_URL = "http://localhost:5000";
export const API_URL = "https://inupiaqonline.com/api";

// let dictionary = [];

// let usagedictionary = [];
// var usagedictionary_dict = {};

// let postbaseusage = {};
// let postbaseusagelist = [];

let audiolibrary = [];
// let demonstrativedata = {};
let examplesentenceaudio1 = [];
let conversational2 = [];
let phrasebookaudio3 = [];
let oneandtwo = [];
let twoandthree = [];
let oneandthree = [];

let urlDictionary = {}
let dictionarySearchList = []
let audioSearchList = [];
let examples_parse = {}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dictionarySearchList:[],
      audioSearchList: [],
      audiolibrary:[],
      urlDictionary: {},
      examples_parse:{},
      passwordGood:true,

      // dictionarydict: {},
      // postbaseusage:{},
      // postbaseusagelist:[],
      // usagedictionary: [],
      // usagedictionary_dict: {},
      // demonstrativedata: {},
      innerWidth:window.innerWidth,
      innerHeight:window.innerHeight,
      progressData1:0,
      progressData2:0,
      progressData3:0,
      progressData4:0,
      fullProgress:0,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.progressData1 !== prevState.progressData1 || this.state.progressData2 !== prevState.progressData2 || this.state.progressData3 !== prevState.progressData3) {
      this.setState({fullProgress:((this.state.progressData1+this.state.progressData2+this.state.progressData3)/12616540)*100})
    }
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight });
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);

    if (Object.keys(urlDictionary).length === 0) {
      axios
        .get(API_URL + "/tlingiturldictionary/all", {
          onDownloadProgress: (progressEvent) => {
            // const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
            // console.log("onDownloadProgress1", totalLength);
            // if (totalLength !== null) {
              // this.progressData1 = 
            this.setState({progressData1:Math.round( progressEvent.loaded )})
              // console.log(this.progressData1)
            // }
          },
        }).then(response => {
          urlDictionary = response.data;
          // let dictionaryEntry = {}
          // let tlingitAudioKeys = {}
          console.log('Fetched urlDictionary');
          // console.log(urlDictionary)
          Object.keys(urlDictionary).forEach(entry => {
            // dictionaryEntry = urlDictionary[entry]
            urlDictionary[entry]['url'] = entry
            urlDictionary[entry]['glossSearch'] = urlDictionary[entry]['gloss'].join('; ')
            urlDictionary[entry]['lexicon'] = urlDictionary[entry]['lexicon'].replaceAll('x̱','x̲')
            urlDictionary[entry]['theme'] = urlDictionary[entry]['theme'].replaceAll('x̱','x̲')
            // if ('dialect' in urlDictionary[entry]) {
              // urlDictionary[entry]['dialect'] = urlDictionary[entry]['dialect']
            // }
            // if ('soundfile' in urlDictionary[entry]) {
            //   // urlDictionary[entry]['soundfile'] = urlDictionary[entry]['soundfile']
            //   let mp3Id = urlDictionary[entry]['soundfile'].split('.mp3')[0]
            //   // console.log(mp3Id)
            //   if (!(mp3Id in tlingitAudioKeys)) {
            //     tlingitAudioKeys[mp3Id] = {
            //         'key': mp3Id,
            //         't': urlDictionary[entry]['lexicon'], 
            //         'tnormal': urlDictionary[entry]['lexicon'].replaceAll('k̲','k').replaceAll('x̲','x').replaceAll('x̱','x̲'), 
            //         'e': urlDictionary[entry]['glossSearch'], 
            //         'mp3':urlDictionary[entry]['soundfile'],
            //         'type':'e',
            //         'lexiconSources':[
            //             {
            //             'url':entry,
            //             'lexicon':urlDictionary[entry]['lexicon'],
            //             'gloss':urlDictionary[entry]['gloss'],
            //             'pos':urlDictionary[entry]['pos'],                        
            //             },
            //             ],
            //         }
            //   } else {
            //     tlingitAudioKeys[mp3Id]['lexiconSources'].concat({
            //         'url':entry,
            //         'lexicon':urlDictionary[entry]['lexicon'],
            //         'gloss':urlDictionary[entry]['glossSearch'],
            //         'pos':urlDictionary[entry]['pos'],                        
            //         },
            //         )
            //   }

            // }
            // dictionarySearchList.push(urlDictionary[entry])

            // if ("examples" in urlDictionary[entry]) {
            //   urlDictionary[entry]['examples'].map((ex,ind)=>{
            //     if (ex[3].length > 0) {
            //       // urlDictionary[entry]['example'+(ind+1).toString()+'t'] = ex[1]
            //       // urlDictionary[entry]['example'+(ind+1).toString()+'e'] = ex[2]
            //       let tstring = ""
            //       ex[1].map((k)=>{tstring+=k[0]+' '})
            //       // console.log(tstring)
            //       if (!(ex[0] in tlingitAudioKeys)) {
            //         tlingitAudioKeys[ex[0]] = {
            //             'key': ex[0],
            //             't': tstring.trim(), 
            //             'tnormal': tstring.trim().replaceAll('k̲','k').replaceAll('x̲','x').replaceAll('x̱','x̲').replaceAll('«','“').replaceAll('»','”'), 
            //             'e': ex[2], 
            //             'mp3':ex[3],
            //             'type':'e',
            //             'lexiconSources':[
            //                 {
            //                 'url':entry,
            //                 'lexicon':urlDictionary[entry]['lexicon'],
            //                 'gloss':urlDictionary[entry]['gloss'],
            //                 'pos':urlDictionary[entry]['pos'],                        
            //                 },
            //                 ],
            //             }
            //       } else {
            //         // console.log('dup')
            //         tlingitAudioKeys[ex[0]]['lexiconSources'] = [...tlingitAudioKeys[ex[0]]['lexiconSources'],
            //             ...[{'url':entry,
            //             'lexicon':urlDictionary[entry]['lexicon'],
            //             'gloss':urlDictionary[entry]['gloss'],
            //             'pos':urlDictionary[entry]['pos'],                        
            //             }]
            //             ]
            //       }
            //     }
            //   })
            // }
            // console.log(entry)
            // let moods = {'perfective':'','imperfective':'','future':'','repetitive_imperfective':'','perfective_habitual':'','progressive_imperfective':'','imperative':'','prohibitive':'','conditional':'','hortative':''}
            // let currentMood = ''
            // if ("verb_paradigms" in urlDictionary[entry]) {
            //   Object.keys(urlDictionary[entry]['verb_paradigms']).map((ex,ind)=>{
            //     Object.keys(urlDictionary[entry]['verb_paradigms'][ex][1]).map((sentences,sind)=>{
            //       // console.log(ex,ind,sentences,sind,urlDictionary[entry]['verb_paradigms'][ex][0])
            //       if (urlDictionary[entry]['verb_paradigms'][ex][1][sentences][1].length > 0) {
            //         if (urlDictionary[entry]['verb_paradigms'][ex][1][sentences][1].join('; ') !== urlDictionary[entry]['glossSearch']) {
            //           if (urlDictionary[entry]['verb_paradigms'][ex][0] in moods) {
            //             if (sentences == '3s')
            //             moods[urlDictionary[entry]['verb_paradigms'][ex][0]] = [urlDictionary[entry]['verb_paradigms'][ex][1][sentences][0].join('; '), urlDictionary[entry]['verb_paradigms'][ex][1][sentences][1].join('; ')]
            //             console.log(moods)
            //           }
            //         } else {
            //           currentMood = urlDictionary[entry]['verb_paradigms'][ex][0]
            //         }    
            //       }
            //     })
            //   })
            // }



            // if ("verb_paradigms_roots" in urlDictionary[entry]) {
            //   let xind = 0
            //   Object.keys(urlDictionary[entry]['verb_paradigms_roots']).map((ex,ind)=>{
            //     Object.keys(urlDictionary[entry]['verb_paradigms_roots'][ex][1]).map((sentences,sind)=>{
            //       // console.log(urlDictionary[entry],sentences,sind,ex,ind,urlDictionary[entry]['verb_paradigms_roots'][ex][1][sentences])
            //       if (urlDictionary[entry]['verb_paradigms_roots'][ex][1][sentences][1].length > 0) {
            //           if (urlDictionary[entry]['verb_paradigms_roots'][ex][0] in moods && moods[urlDictionary[entry]['verb_paradigms_roots'][ex][0]] == '' && urlDictionary[entry]['verb_paradigms_roots'][ex][0] != currentMood) {
            //             moods[urlDictionary[entry]['verb_paradigms_roots'][ex][0]] = [urlDictionary[entry]['verb_paradigms_roots'][ex][1][sentences][0].join('; '), urlDictionary[entry]['verb_paradigms_roots'][ex][1][sentences][1].join('; ')]
            //             console.log(moods)
            //           }       
            //       }
            //     })
            //   })
            // }

            // console.log(moods)


            // dictionary[entry]['fullDefinitionNormalized'] = dictionary[entry]['fullDefinition'].replaceAll(',',' ').replaceAll('⎡(i)⎤','').replaceAll('⎡(t)⎤','');
            // dictionary[entry]['keyStringNormal'] = dictionary[entry]['keyString'].replaceAll('ġ','g').replaceAll('ñ','n').replaceAll('ḷ','l').replaceAll('ł','l').replaceAll('ł̣','l').replaceAll('-','').replaceAll(',',' ').replaceAll('(i)','').replaceAll('(t)','');
            // dictionarydict[dictionary[entry]['url']]=dictionary[entry]
          
            dictionarySearchList.push(urlDictionary[entry])

          })

          // Object.keys(tlingitAudioKeys).forEach(entry => {
          //   audioSearchList.push(tlingitAudioKeys[entry])
          // })

          // audiolibrary = response.data;
          // console.log('Fetched AudioLibrary');
          // audiolibrary.map((k,index)=> audiolibrary[index]['tnormal'] = audiolibrary[index]['t'].replaceAll('k̲','k').replaceAll('x̲','x'))
          // console.log('Processed AudioLibrary Normalized')
          // this.setState({ audiolibrary: audiolibrary });



          // console.log('Processed dictionary Normalized')
          // console.log(dictionary)
          this.setState({dictionarySearchList: dictionarySearchList, urlDictionary: urlDictionary});
        });
    }
    else {
      this.setState({dictionarySearchList: dictionarySearchList, urlDictionary: urlDictionary});
    }


    // if (Object.keys(examples_parse).length === 0) {
    //   axios
    //     .get(API_URL + "/tlingitsentenceparse/all", {
    //       onDownloadProgress: (progressEvent) => {
    //         // const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
    //         // console.log("onDownloadProgress1", totalLength);
    //         // if (totalLength !== null) {
    //           // this.progressData1 = 
    //         // this.setState({progressData1:Math.round( progressEvent.loaded )})
    //           // console.log(this.progressData1)
    //         // }
    //       },
    //     }).then(response => {
    //       examples_parse = response.data;
    //       // let dictionarydict = {}
    //       console.log('Fetched examples_parse');
    //       // Object.keys(dictionary).forEach(entry => {
    //       //   dictionary[entry]['fullDefinitionNormalized'] = dictionary[entry]['fullDefinition'].replaceAll(',',' ').replaceAll('⎡(i)⎤','').replaceAll('⎡(t)⎤','');
    //       //   dictionary[entry]['keyStringNormal'] = dictionary[entry]['keyString'].replaceAll('ġ','g').replaceAll('ñ','n').replaceAll('ḷ','l').replaceAll('ł','l').replaceAll('ł̣','l').replaceAll('-','').replaceAll(',',' ').replaceAll('(i)','').replaceAll('(t)','');
    //       //   dictionarydict[dictionary[entry]['url']]=dictionary[entry]
    //       // })
    //       // console.log('Processed dictionary Normalized')
    //       // console.log(dictionary)
    //       this.setState({ examples_parse: examples_parse });
    //     });
    // }
    // else {
    //   this.setState({ examples_parse: examples_parse });
    // }

    // if (Object.keys(postbaseusage).length === 0 || postbaseusagelist.length === 0) {
    //   axios
    //     .get(API_URL + "/inupiaqsearchableusagepostbasedict/all", {
    //       onDownloadProgress: (progressEvent) => {
    //         // const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
    //         // console.log("onDownloadProgress1", totalLength);
    //         // if (totalLength !== null) {
    //           // this.progressData1 = 
    //         this.setState({progressData4:Math.round( progressEvent.loaded )})
    //           // console.log(this.progressData1)
    //         // }
    //       },
    //     }).then(response => {
    //       postbaseusage = response.data;
    //       Object.keys(postbaseusage).forEach(entry => {
    //         postbaseusagelist.push(postbaseusage[entry])
    //       })
    //       console.log('Fetched postbases',postbaseusage);
    //       console.log('Fetched postbase list',postbaseusagelist);
    //       // postbaseusage.map((k,index)=> audiolibrary[index]['inormal'] = audiolibrary[index]['i'].replaceAll('ġ','g').replaceAll('ñ','n').replaceAll('ḷ','l').replaceAll('ł','l').replaceAll('ł̣','l').replaceAll('-','').replaceAll('.','').replaceAll('?',''))
    //       // FIGURE OUT HOW TO SEARCH WITH SYMBOLS FOR INUPIAQ ONLY

    //       this.setState({ postbaseusagelist:postbaseusagelist, postbaseusage: postbaseusage });
    //     });
    // }
    // else {
    //   this.setState({ postbaseusage: postbaseusage });
    // }


    // if (usagedictionary.length === 0) {
    //   axios
    //     .get(API_URL + "/inupiaqsearchableusagedict/all", {
    //       onDownloadProgress: (progressEvent) => {
    //         // const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
    //         // console.log("onDownloadProgress2", totalLength);
    //         // if (totalLength !== null) {
    //           // this.progressData2 = Math.round( (progressEvent.loaded * 100) / totalLength );
    //         this.setState({progressData2:Math.round( progressEvent.loaded)})
    //           // console.log(this.progressData2)
    //         // }
    //       },
    //     }).then(response => {
    //       // usagedictionary_dict = response.data;
    //       // this.setState({ usagedictionary: response.data })
    //       console.log('Fetched usage dictionary');
    //       // console.log(response.data)
    //       Object.keys(response.data).forEach(entry => Object.keys(response.data[entry]).forEach(e => {
    //         if (!(entry in usagedictionary_dict)) {
    //           usagedictionary_dict[entry] = {}
    //         }
    //         usagedictionary_dict[entry][e] = {}
    //         usagedictionary_dict[entry][e]['usageWordNormal'] = response.data[entry][e]['a'][0].replaceAll('ġ','g').replaceAll('ñ','n').replaceAll('ḷ','l').replaceAll('ł','l').replaceAll('ł̣','l').replaceAll('-','').replaceAll('>','').replaceAll('&','').replaceAll('↞','').replaceAll('↠','');
    //         usagedictionary_dict[entry][e]['usageDefinition'] = response.data[entry][e]['c'][0].replaceAll('it is ','').replaceAll('she is ','').replaceAll('he is ','').replaceAll('the one ','');
    //         usagedictionary_dict[entry][e]['usageWords'] = response.data[entry][e]['a'];
    //         usagedictionary_dict[entry][e]['backendCalls'] = response.data[entry][e]['b'];
    //         usagedictionary_dict[entry][e]['newUsageDefinitions'] = response.data[entry][e]['c'];
    //         usagedictionary_dict[entry][e]['url'] = response.data[entry][e]['d'];
    //         usagedictionary_dict[entry][e]['num'] = response.data[entry][e]['e'];
    //         usagedictionary_dict[entry][e]['t'] = response.data[entry][e]['f'];
    //         usagedictionary_dict[entry][e]['fullDefinition'] = response.data[entry][e]['g'];
    //         usagedictionary_dict[entry][e]['thisDefinition'] = response.data[entry][e]['h'];
    //         usagedictionary_dict[entry][e]['properties'] = response.data[entry][e]['i'];
    //         usagedictionary_dict[entry][e]['tag'] = response.data[entry][e]['j'];
    //         usagedictionary_dict[entry][e]['audioMatrix'] = response.data[entry][e]['k'];
    //         if ('l' in response.data[entry][e]) {
    //           usagedictionary_dict[entry][e]['halftransitiveKeys'] = response.data[entry][e]['l'];              
    //         }
    //         if ('m' in response.data[entry][e]) {
    //         usagedictionary_dict[entry][e]['keytags'] = response.data[entry][e]['m'];
    //         }

    //         usagedictionary.push(usagedictionary_dict[entry][e])
    //       }

    //       )) // create dictionary list dictionary
    //       console.log(usagedictionary)
    //       // Object.entries(usagedictionary_dict).forEach(entry => Object.entries(entry[1]).forEach(e => usagedictionary.push(e[1]))) // create dictionary list dictionary

    //       this.setState({ usagedictionary: usagedictionary, usagedictionary_dict: usagedictionary_dict });
    //     });
    // }
    // else {
    //   this.setState({ usagedictionary: usagedictionary, usagedictionary_dict: usagedictionary_dict });
    // }

    if (audiolibrary.length === 0) {
      axios
        .get(API_URL + "/tlingitaudiolibrary/all", {
          onDownloadProgress: (progressEvent) => {
            // const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
            // console.log("onDownloadProgress3", totalLength);
            // if (totalLength !== null) {
            this.setState({progressData3:Math.round( progressEvent.loaded )})
              // this.progressData3 = Math.round( (progressEvent.loaded * 100) / totalLength );
              // console.log(this.progressData3)
            // }
          },
        }).then(response => {
          audiolibrary = response.data;
          console.log('Fetched AudioLibrary');
          // console.log(audiolibrary)
          let phrasebookaudio = []
          let conversational = []
          let examplesentenceaudio = []
          audiolibrary.map((k,index)=> {
            // audiolibrary[index]['tnormal'] = audiolibrary[index]['t'].join(" ").replaceAll('k̲','k').replaceAll('x̲','x').replaceAll('«','').replaceAll('«','').replaceAll('»','').replaceAll('»','')
            audiolibrary[index]['tnormal'] = audiolibrary[index]['t'].join(" ").replaceAll('«','').replaceAll('«','').replaceAll('»','').replaceAll('»','')
            audiolibrary[index]['toriginal'] = audiolibrary[index]['t'].join(" ").replaceAll('«','“').replaceAll('«','“').replaceAll('»','”').replaceAll('»','”')
            audiolibrary[index]['t'] = this.removeArtifacts(audiolibrary[index]['t'].join(" "))
            audiolibrary[index]['e'] = audiolibrary[index]['e'].join(" ").replaceAll('k̲','k').replaceAll('x̲','x')

            if (audiolibrary[index]['key'].includes('phrasebook-')) {
              phrasebookaudio3.push(audiolibrary[index])
            } else if (audiolibrary[index]['key'].includes('_')) {
              conversational2.push(audiolibrary[index])
            } else {
              examplesentenceaudio1.push(audiolibrary[index])
            }
          })

          let oneandtwo = [...examplesentenceaudio1,...conversational2]
          let twoandthree = [...conversational2,...phrasebookaudio3]
          let oneandthree = [...examplesentenceaudio1,...phrasebookaudio3]
          // audiolibrary.map((k,index)=> audiolibrary[index]['enormal'] = audiolibrary[index]['e'].join(' ').replaceAll('k̲','k').replaceAll('x̲','x'))
          // audiolibrary.map((k,index)=> audiolibrary[index]['e'] = audiolibrary[index]['e'].join(" ").replaceAll('k̲','k').replaceAll('x̲','x'))
          // console.log('Processed AudioLibrary Normalized')
          this.setState({examplesentenceaudio1:examplesentenceaudio1, conversational2:conversational2, phrasebookaudio3: phrasebookaudio3, oneandtwo: oneandtwo, twoandthree: twoandthree, oneandthree: oneandthree, audiolibrary: audiolibrary });
        });
    }
    else {
      this.setState({examplesentenceaudio1:examplesentenceaudio1, conversational2:conversational2, phrasebookaudio3: phrasebookaudio3, oneandtwo: oneandtwo, twoandthree: twoandthree, oneandthree: oneandthree, audiolibrary: audiolibrary });
    }

    // if (Object.keys(demonstrativedata).length === 0) {
    //   axios
    //     .get(API_URL + "/demonstrativedata/all").then(response => {
    //       demonstrativedata = response.data;
    //       console.log('Fetched demonstrativedata');
    //       // console.log(demonstrativedata)
    //       this.setState({ demonstrativedata: demonstrativedata });
    //     });
    // }
    // else {
    //   this.setState({ demonstrativedata: demonstrativedata });
    // }

  }

  removeArtifacts = (sentence) => {
    sentence = sentence.replaceAll('«','“').replaceAll('«','“').replaceAll('»','”').replaceAll('»','”')
    let matches1 = sentence.match(/{.*?}/g)
    // let matches2 = sentence.match(/\[.*?\]/g)
    // console.log(matches2)
    if (matches1 !== null) {
      if (matches1 !== null) {
        matches1.map((m) => sentence = sentence.replaceAll(m,''))            
      }
      // if (matches2 !== null) {
      //   matches1.map((m) => sentence = sentence.replaceAll(m,''))            
      // }
    }
    return sentence
  }

  render() {
    // console.log(window.innerHeight)
    // console.log(this.state)
    return(
    <BrowserRouter>
    <div>
{/*        {!this.state.passwordGood ?
              <Dimmer active>
                <div style={{width:'300px'}}>
                <Input
                  placeholder="Type passcode"
                  // action={{ icon: (this.state.yugtunAnalyzer ? 'search' : null), transparent:true,size:'huge', onClick: () => 
                  // {this.state.yugtunAnalyzer ? this.inputClicked() : null}
                  // }}
                  // icon={<Icon name='x' onClick={console.log('hi')} link />}
                  // iconPosition='right'
                  size='huge'
                  spellcheck="false"
                  onChange={(event, data)=>{this.setState({'password':data.value})}}
                  // value={this.state.search}
                  // onKeyPress={this.onKeyPress}
                  fluid 
                  action={{
                    content: "Enter",
                    onClick: (event, data) => (this.state.password.toLowerCase() == 'aan' ? this.setState({passwordGood:true}):null)
                  }}
                   />    
              </div>
              </Dimmer>
              :
              null
        }
        {
            this.state.passwordGood && Object.keys(this.state.examples_parse).length !== 0 && this.state.dictionarySearchList.length !== 0 && this.state.audiolibrary.length !== 0 && this.state.audioSearchList.length !== 0 && Object.keys(this.state.urlDictionary).length !== 0 ?*/}
            <Switch>
              <Route exact path='/' render={(props) => 
                (this.state.dictionarySearchList.length !== 0 && this.state.audiolibrary.length !== 0 ?
                  <Home dictionarySearchList={this.state.dictionarySearchList} audiolibrary={this.state.audiolibrary} examplesentenceaudio1={this.state.examplesentenceaudio1} conversational2={this.state.conversational2} phrasebookaudio3={this.state.phrasebookaudio3} oneandtwo={this.state.oneandtwo} twoandthree={this.state.twoandthree} oneandthree={this.state.oneandthree} urlDictionary={this.state.urlDictionary} {...props} />
                  :
                  <div>
                  <Home dictionarySearchList={this.state.dictionarySearchList} audiolibrary={this.state.audiolibrary} examplesentenceaudio1={this.state.examplesentenceaudio1} conversational2={this.state.conversational2} phrasebookaudio3={this.state.phrasebookaudio3} oneandtwo={this.state.oneandtwo} twoandthree={this.state.twoandthree} oneandthree={this.state.oneandthree} urlDictionary={this.state.urlDictionary} {...props} />
                  <Dimmer style={{height:(window.innerHeight > 870 ? window.innerHeight: 870)}} active>
                    <Loader style={{marginTop:-((870-window.innerHeight)/2)}} size='massive'>
                      Loading...
                    <Progress style={{marginTop:20}} percent={this.state.fullProgress} size='tiny' />
                    </Loader>
                  </Dimmer>
                  </div>
                )
              }/>
              <Route exact path='/dialogues/:num' render={(props) => <Conversations {...props} />}/>
              <Route exact path='/report' component={Report}></Route>
              <Route exact path='/map' component={TlingitMap}></Route>
              <Route exact path='/phrasebook/:num' component={Phrasebook}></Route>
              <Route exact path='/:word' render={(props) => <Entry {...props} />}/>
            </Switch>     
{/*            :
            (this.state.passwordGood ?
              <div>
              <Dimmer style={{height:(window.innerHeight > 870 ? window.innerHeight: 870)}} active>
                <Loader style={{marginTop:-((870-window.innerHeight)/2)}} size='massive'>
                  Loading...
                <Progress style={{marginTop:20}} percent={this.state.fullProgress} size='tiny' />
                </Loader>
              </Dimmer>
              </div>
              :
              null
            )
        }*/}

    </div>
    </BrowserRouter>
    );
  }
};

export default App;
