import React, { Component, createRef } from 'react';
import { Segment, Container, Input, List, Label, Divider, Tab, Grid, Header, Image, Button, Menu, MenuItem, Dropdown, Icon, Transition, Checkbox, Popup } from 'semantic-ui-react';
import { Link, useParams } from 'react-router-dom';
import fuzzysort from 'fuzzysort';
import axios from 'axios';
import { API_URL } from '../App.js';
import { YugtunLoader, WordItem, TagColors } from './HomeHelper.js';
import { customFontFam3, customTitleFontFam3, customFirefoxTitleFontFam } from './constants/constants.js';
import { sanitize } from 'dompurify';
import {categories} from './constants/categories.js';
import {categoriesUrlLookup} from './constants/categoriesUrlLookup.js';

// import { dialogueGenerator } from './constants/dialogueListGenerator.js';
//test

import * as Scroll from 'react-scroll';
// import { Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import ReactGA from "react-ga4";
ReactGA.initialize("G-BCN5V0JQN1")

var scroll    = Scroll.animateScroll;

const options1 = {
  keys: ['lexicon','glossSearch','verbp1t','verbp1e','verbp2t','verbp2e','verbp3t','verbp3e','verbp4t','verbp4e','verbp5t','verbp5e','verbp6t','verbp6e','verbp7t','verbp7e','verbp8t','verbp8e','verbp9t','verbp9e','verbp10t','verbp10e'],
  limit: 50, // don't return more results than you need!
  threshold: -Infinity, // don't return bad results
};

// const options2 = {
//   keys: ['theme','definition','verbp1t2','verbp1e2','verbp2t2','verbp2e2','verbp3t2','verbp3e2','verbp4t2','verbp4e2','verbp5t2','verbp5e2','verbp6t2','verbp6e2','verbp7t2','verbp7e2','verbp8t2','verbp8e2','verbp9t2','verbp9e2','verbp10t2','verbp10e2'],
//   limit: 50, // don't return more results than you need!
//   threshold: -Infinity, // don't return bad results
// };

// const optionsUsageFuzzy = {
//   keys: ['usageWordNormal','usageDefinition'],
//   limit: 50, // don't return more results than you need!
//   threshold: -10000, // don't return bad results
//   scoreFn: a => {
//     // console.log(a)
//     return Math.max(a[0] ? ("keytags" in a.obj ? a[0].score+35 : a[0].score) : -10000, 
//                     a[1] ? ("keytags" in a.obj ? a[1].score+35 : a[1].score) : -10000)
//   }
// };

const options3 = {
  keys: ['tnormal', 'e'],
  limit: 50, // don't return more results than you need!
  threshold: -10000, // don't return bad results
};

const tabOptions = [
  {
    key: 0,
    text: <div style={{fontSize:'17px'}}><span style={{fontWeight:'500'}}>Dictionary</span></div>,
    value: 0,
  },
  {
    key: 2,
    text: <div style={{fontSize:'17px'}}><span style={{fontWeight:'500'}}>Audio Clips</span></div>,
    value: 2,
  },
  {
    key: 3,
    text: <div style={{fontSize:'17px'}}><span style={{fontWeight:'500'}}>Categories</span></div>,
    value: 3,
  },
]


let customFontFam = customFontFam3
let customTitleFontFam = customTitleFontFam3
let customFontFam2 = {fontFamily:customTitleFontFam3}
let letterbutton = {fontFamily:customFontFam3,paddingTop:'10px',paddingBottom:'10px',paddingRight:'14px',paddingLeft:'14px'}
const isFirefox = typeof InstallTrigger !== 'undefined';

if (isFirefox) {
  customFontFam = customFirefoxTitleFontFam
  customTitleFontFam = customFirefoxTitleFontFam
  customFontFam2 = {fontFamily:customFirefoxTitleFontFam}
  letterbutton = {fontFamily:customFirefoxTitleFontFam,paddingTop:'10px',paddingBottom:'10px',paddingRight:'14px',paddingLeft:'14px'}
}

let tlingitConversationData = {
"1":["001.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/01RuthNora.html",['44', '40']],
"2":["002.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/2EvelynMarsha2.html",['16', '41']],
"3":["003.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/3SmithJoe.html",['64', '11']],
"4":["004.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/4EthelAnita.html",['35', '7']],
"5":["005.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/5ArchieGeorge.html",['54', '23']],
"6":["006.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/6ArchieGeorge.html",['54', '23']],
"7":["007.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/7FlorenceRon.html",['42', '14']],
"8":["008.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/8MarthaMarsha.html",['62', '16']],
"9":["009.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/9IdaCalmagne.html",['48']],
"10":["010.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/10DavidGeorge.html",['30', '23']],
"11":["011.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/11DavidGeorge.html",['30', '23']],
"12":["012.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/12HelenFloBaby.html",['36', '42']],
"013-014":["013-014.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/13-14MargaretMarsha.html",['65', '16']],
"15":["015.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/15LillianAnita.html",['55', '82']],
"16":["016.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/16WalterJohn.html",['59', '29']],
"17":["017.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/17Cook.html",['23', '36', '53']],
"18":["018.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/18Dance.html",['1', '55']],
"19":["019.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/19Eat.html",['23', '36', '53']],
"20":["020.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/20NoraFred.html",['44', '15', '77', '53', '10']],
"21":["021.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/21CyrilG.html",['39', '37']],
"22":["022.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/22CyrilG.html",['39', '37']],
"23":["023.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/23IreneLillian.html",['50', '82']],
"24":["024.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/24LenaNellie.html",['38', '4']],
"25":["025.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/25NellieLena.html",['38', '4']],
"26":["026.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/26LenaNellie.html",['38', '4']],
"27":["027.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/27LorraineVincent.html",['70', '83', '53']],
"28":["028.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/28LorraineVincent.html",['70', '83', '53']],
"29":["029.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/29LenaHans.html",['4', '53']],
"30":["030.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/30LenaHans.html",['4', '53']],
"31":["031.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/31RamosChester.html",['74', '53']],
"32":["032.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/32RamosChester.html",['74', '53']],
"33":["033.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/33GeorgeLena.html",['74', '4']],
"34":["034.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/34GeorgeLena.html",['74', '4']],
"35":["035.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/35Atlin.html",['64', '18', '56', '44']],
"36":["036.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/",['25', '56', '44']],
"037-038":["037-038.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/37-38AtlinBerries.html",['25', '56', '44', '53']],
"37":["037.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/37AtlinSrprizLk.html",['81', '64', '25', '56', '44']],
"38":["038.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/38AtlinGrotto.html",['25', '56', '44']],
"039a":["039a.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/39Atlin.html",['64', '53', '25', '18', '68', '44']],
"039b":["039b.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/",['25', '67', '75', '33', '49', '44']],
"40":["040.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/",['25', '75', '67', '6', '33', '44']],
"41":["041.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/",['25', '75', '67', '6', '33', '49']],
"42":["042.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/42Teslin.html",['44', '25', '33', '53', '17', '71']],
"43":["043.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/43TeslinMen.html",['17', '71']],
"44":["044.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/",['17', '71']],
"45":["045.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/",['8', '12', '64', '28']],
"46":["046.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/",['28', '8', '64']],
"47":["047.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/",['28', '8', '64', '53']],
"48":["048.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/48Carcross.html",['44', '48', '25', '49', '12', '24']],
"49":["049.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/49Carcross.html",['44', '48', '25', '49', '12', '24']],
"50":["050.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/",['44', '64', '25', '56', '62']],
"51":["051.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/",['44', '64', '25', '56', '62']],
"52":["052.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/",['44', '64', '25', '56', '62']],
"53":["053.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/",['62', '16']],
"54":["054.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/54SoboleffRamos.html",['72', '80', '59', '74']],
"56":["056.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/",['40', '16']],
"57":["057.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/57HelenMargaret.html",['36', '58']],
"58":["058.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/58Gunaakw.html",['15']],
"59":["059.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/59Lena.html",['4']],
"60":["060.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/60MargaretMarsha.html",['65', '16']],
"61":["061.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/61MargaretMarsha.html",['65', '16']],
"062a":["062a.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/62Color.html",['65', '16']],
"062b":["062b.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/62MargaretFred.html",['65', '15']],
"63":["063.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/63SelinaMargaret.html",['65', '25']],
"64":["064.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/64SelinaMargaret.html",['25', '58']],
"65":["065.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/65GeorgePaul.html",['27', '31']],
"66":["066.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/66GeorgePaul.html",['27', '31']],
"67":["067.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/67LilyMarge.html",['47', '65']],
"68":["068.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/68AdelineGenevieve.html",['45', '9']],
"069-070":["069-070.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/69-70KatieMarge.html",['19', '58']],
"69":["069.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/69SamHans.html",['61', '19', '58', '53']],
"71":["071.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/71DaltonDutson.html",['65', '21', '53']],
"72":["072.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/72AdelineDeborah.html",['45', '69']],
"73":["073.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/73AdelineMargaret.html",['45', '69', '65']],
"74":["074.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/74AdelineLarry.html",['45', '22', '65']],
"75":["075.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/75JennieAdeline.html",['2', '45']],
"76":["076.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/76SelinaMargaret.html",['65', '25']],
"77":["077.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/77SelinaHelenKeriRyker.html",['25', '36', '34', '51']],
"78":["078.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/78SelinaHelenKeriRyker.html",['25', '36', '34', '51']],
"79":["079.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/79MargaretDutson.html",['65']],
"80":["080.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/80RuthMarshaKianaLance.html",['40', '16', '79', '43', '13', '3', '78']],
"81":["081.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/81AlHarold.html",['3', '13']],
"82":["082.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/",['32', '15']],
"083a":["083a.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/83aVanSongComposing.html",['46', '40', '76', '52', '26']],
"083b":["083b.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/83bVanRescue.html",['46', '40', '76', '52', '26']],
"84":["084.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/84MargaretMarshaKaneegwal.html",['65', '16']],
"85":["085.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/85MargaretMarshaKaneegwa.html",['65', '16']],
"86":["086.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/86GeorgeMargaret.html",['65', '23']],
"87":["087.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/87GeorgeMargaret.html",['65', '23']],
"88":["088.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/88GeorgeMargaret.html",['65', '23']],
"89":["089.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/89GeorgeMargaret.html",['65', '23']],
"90":["090.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/90MargaretMarsha.html",['65', '16']],
"91":["091.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/91MabelLiz.html",['20', '60', '25']],
"92":["092.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/",['5']],
"93":["093.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/",['57', '25']],
"94":["094.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/94FrankSelina.html",['73', '25']],
"95":["095.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/95LizVernaSelina.html",['63', '66', '25']],
"96":["096.mp3","https://slexildata.artsrn.ualberta.ca/tlingit/96VernaLizSelina.html",['63', '66', '25']],
}

let speakerIDs = {
// [Name, Alternate Spellings, Clan, Father's Clan, Village or K̲waan, Clan House]
"1":["Aan Yax̲ Saxeex June Pegues","","","","",""],
"2":["Aansheix̲ Jennie Lindoff","","Chookaneidi","Lukaax̲ádi","Hoonah","Xoots Hít : Xoots kúdi Hít"],
"3":["Aanx’isk’oox Al McKinley","","Wooshkeetaan","T'ak̲deintaan ?","Burners Bay","?"],
"4":["Achkwéi Lena Farkas","","K̲waashk'i K̲waan","","Yakutat","Dís Hít"],
"5":["Alan Zuboff","","L'eeneidi","(Lester Zuboff)","Aangoon","Téel' Hít (?)"],
"6":["Chukateen Jane Smarch","","","","",""],
"7":["Daasdiyáa Ethel Makinen","","L'uknax̲.ádi ","Kaagwaantaan","Sitka","Kayaashká Hít?"],
"8":["Dagé Winnie Atlin","","","","Dak̲ká",""],
"9":["Deiwjee Genevieve Cook","","T'ak̲deintaan","Kaagwaantaan (?)","Hoonah",""],
"10":["Dzeiwsh James Crippen","","Deisheetaan","","",""],
"11":["Gax̲daakashú Joe Hotch","","Kaagwaantaan","Gaanax̲teidi (?)","Klukwan","Gaaw hít"],
"12":["Gus'dutéen Bessie Jim","","","","",""],
"13":["G̲ooch Shaayí Harold Martin","","","","",""],
"14":["G̲oochtlein Ron Grant","","K̲aach.ádi","Kaagwaantaan/Chookaneidí","",""],
"15":["G̲unaak'w Fred White","","Shangukeidí","L'uknax̲.ádi","G̲unáax̲oo","Xeitl Hít"],
"16":["G̲uneiwtí Marsha Hotch","G̲unéiwti Marsha Hotch","Gaanax̲ádi","Shangukeidí","Klukwan",""],
"17":["Jeesk'oo Jimmie Johnston","","","","",""],
"18":["Jigéi Jackie Williams","Jigéi/Yaak'w Góos' Jackie Williams","","","",""],
"19":["Jinduláa Katherine Hanlon","","","","",""],
"20":["Kaach Ḵúleix' Mabel Jack","","","","",""],
"21":["Kaajinaat George Dalton, Jr.","","T'ak̲deintaan","Kaagwaantaan","Hoonah",""],
"22":["Kaakwelaaxt Lootooskú Larry Sanders","","","","",""],
"23":["Kaaxwaan Éesh George Davis","","T'ak̲deintaan","Kaagwaantaan","Aangoon",""],
"24":["Kaltín Susan Jim","","Wooshkeetaan (?)","","",""],
"25":["Kaséix̲ Selina Everson","","Deisheetaan","Kaagwaantaan","Aangoon",""],
"26":["Kawduk’eit Tláa Vida Davis","","T'ak̲deintaan ?","","Sitka",""],
"27":["Kaxwaan Éesh George Davis","","T'ak̲deintaan","","",""],
"28":["Kax̲lige Norman James","","","","",""],
"29":["Keihéenák’w John Martin","","T'ak̲deintaan","Kaagwaantaan","Hoonah","X̲'aakw"],
"30":["Kingeistí David Katzeek","","Shangukeidí","Gaanax̲teidi","Klukwan","Kaadli.aayí Hít"],
"31":["Kinkawduneek Paul Marks","","Lukaax̲ádi","Chookaneidi","Haines","Yeil Hít"],
"32":["Kooshdaak'w Éesh William B Fawcett","","Lukaax̲ádi","","Haines","Yeil Hít"],
"33":["Kèyishí Bessie Cooley","","Ishkahittaan?","","",""],
"34":["Ḵ'aagi Sháawu Keri Eggleston","","","","",""],
"35":["Ḵ'asa See Anita Wright","","","","",""],
"36":["Ḵaachkoo.aaḵw Helen Sarabia","","Gaanax̲teidí","Kaagwaantaan","Haines","Tl'ook Hít (?)"],
"37":["Ḵaagwáask' Ishmael Hope","","Kiks.ádi","Teehíttaan or Naanya.aayí","Wrangel",""],
"38":["Ḵaajín.aa Nellie Lord","","K̲waashk'i K̲waan","","Yakutat","Dís Hít"],
"39":["Ḵaalkáawu Cyril George","","K̲ak'weidí","Kaagwaantaan","Baskey Bay",""],
"40":["Ḵaanáḵ Ruth Demmert","","K̲aachádi","Kaagwaantaan : Chookaneidi","Kake",""],
"41":["Ḵaax̲kwei Evelyn Hotch","","Kaagwaantaan (?)","","",""],
"42":["Ḵaaḵal.aat Florence Marks Sheakley","Ḵaaḵal.aat Florence Sheakley","Lukaax̲ádi","Chookaneidi","Haines","Yeil Hít"],
"43":["Ḵalḵéis' Kiana Twitchell","","Dak̲l'aweidí","Lukaax̲.ádi","",""],
"44":["Ḵeixwnéi Nora Marks Dauenhauer","Ḵeixwnéi Nora Dauenhauer;Ḵeiwnéi Nora Dauenhauer","Lukaax̲ádi","Chookaneidi","Haines","Yeil Hít"],
"45":["Ḵintóo Adeline St. Clair","Ḵintóow Adeline St. Clair","","","",""],
"46":["Ḵudeik Joe Thomas","","","","Kake",""],
"47":["L'x̲éisk Lily White","","Chookaneidi","","",""],
"48":["La.oos Tláa Ida Calmegane","","","","Dak̲ká",""],
"49":["Lasaayí Emma Shorty","","","","Dak̲ká",""],
"50":["Latseenk'i Tláa Irene Lampe","","Teik̲weidí","","",""],
"51":["Ldéx̲' Shaax̲ Ryker Eggleston","","","","",""],
"52":["L’eiwtu Éesh Herman Davis","","L'uknax̲ádi","","Sitka",""],
"53":["Naakil.aan Mark Hans Chester","Nakil.aan Mark Hans Chester;Naakil.aan Hans Mark Chester","L'uknax̲ádi","Shangukeidi/Kaagwaantaan","Yakutat","Daginaa Hít"],
"54":["Neish Archie Cavanaugh, Sr.","","T'ak̲deintaan","","",""],
"55":["Sakaayí Anita Lafferty","Saḵaayí Anita Lafferty","Chookaneidi","","",""],
"56":["Seidayaa Mary Anderson","","Ishkittaan?","Yanyeidí","",""],
"57":["Shaadáax' Daniel Johnson","","","","",""],
"58":["Shak'sháani Margaret Dutson","Shak'sháani Margart Dutson","G̲aanax̲teidí","Yanyeidí","",""],
"59":["Shgóonde Walter Soboleff","","L'eeneidí","","",""],
"60":["Shkanyadaká Elizabeth McCluskey","","","","",""],
"61":["Shtuwaax’eelge Samuel Hanlon","","","","",""],
"62":["Shuwateen Martha VanHeel","Shuwuteen Martha VanHeel","Yanyeidi","","",""],
"63":["Shx̲'oosaxws Verna Johnson","","","","",""],
"64":["Sháayi Éesh Smith Katzeek","","","","",""],
"65":["Shák'sháani Margaret Dutson","Shák’sháani Margaret Dutson","","","",""],
"66":["Shḵanyadaká Elizabeth McCluskey","","","","",""],
"67":["T'aaḵú Tláa Pearl Keenan","","","","",""],
"68":["Thomas Esquiro","","","","",""],
"69":["Tl'oon Shaa Deborah Dalton","","","","",""],
"70":["Tléiḵw Wás'i Lorraine Adams","","L'uknax̲.ádi","","Yakutat","Xíxch' Hít"],
"71":["Tánk Smith Katzeek","","","","",""],
"72":["T’aawchán","","","","",""],
"73":["Uxshkadusneek Frank Jack","","","","",""],
"74":["Woochjix'oo Éesh George Ramos","Woochjix̲oo Éesh George Ramos;Wooshjix̲oo Éesh George Ramos","L'uknax̲.ádi","Filipino","Yakutat","Daginaa Hít"],
"75":["Wooshtudeidu.oo Florence Smarch","","","","",""],
"76":["Xwaanlein Virginia Oliver","","","","",""],
"77":["Xwaayeenáḵ Richard Dauenhauer","","Chookaneidi","","",""],
"78":["X̲'aagi Sháawu Keri Eggleston","","","","",""],
"79":["X̲'unei Lance Twitchell","","","","",""],
"80":["X̲aajaakwtí","","","","",""],
"81":["Yaak'w Góos' Jackie Williams","","","","",""],
"82":["Yaxduláx̲kt' Lillian Austin","","Shangukeidi","","",""],
"83":["Yéiyikanaléin Vincent Johnson","","Shangukeidí","L'uknax̲.ádi","G̲unáax̲oo","Xeitl Hít"],
"J":["Koodéik' Joseph Marks","","Kaagwaantaan","Lukaax̱.ádi ","Tlákw Aan","Leegooshee hít and Gaaw Hít"],
"N":["Lyanwudag̱aat Nae Tumulak","","L'uknax̱ Sháa","Kaagwaantaan","G̱unáax̱oo Ḵwáan","Xíxch' Hít"],
"P":["Paul","","","","",""],
}


class Home extends Component {
	constructor(props) {
		super(props);
    // console.log(props,props.location.state.currentCategory !== 'all')
		this.state = {
			search: props.location.state === undefined || 'currentCategory' in props.location.state && props.location.state.currentCategory !== 'all' ? '' :props.location.state.history[0],
			wordsList: props.location.state === undefined || 'currentCategory' in props.location.state && props.location.state.currentCategory !== 'all' ? [] : props.location.state.history[1],
      activeTabIndex: props.location.state === undefined ? 0 : ('currentCategory' in props.location.state && props.location.state.currentCategory !== 'all' ? 3 : props.location.state.history[3]),
      homeMode: props.location.state === undefined ? 0 : ('currentCategory' in props.location.state && props.location.state.currentCategory !== 'all' ? 3 : props.location.state.history[3]),
      tabValue: props.location.state === undefined ? 0 : ('currentCategory' in props.location.state && props.location.state.currentCategory !== 'all' ? 3 : props.location.state.history[3]),
      activeAudioIndex: props.location.state === undefined || 'currentCategory' in props.location.state && props.location.state.currentCategory !== 'all' ? {} : props.location.state.history[4],

      parseIndex:0,
      playingAudio: false,
      prevSearch: [],
      prevWordsList: [],
      logoup:false,

      currentCategory: props.location.state === undefined ? 'all' :('currentCategory' in props.location.state && props.location.state.currentCategory !== 'all' ? props.location.state.currentCategory:'all'),
      childrenCategories:[],
      parentCategories:[],

      categoriesDisplayed:[],
      // combinedAudio:[],

      useExample:true,
      useConversational:true,
      usePhrasebook:true,
      audioFilterChangeable:true,

      seeMoreAcknowledgments:false,
		}
    // console.log('new')
		this.handleRef = createRef()
		// this.handleRef2 = createRef()

    if (props.location.state !== undefined && 'history' in props.location.state) {
      // console.log('hi',props.location.state.history[2])
      scroll.scrollTo(props.location.state.history[2]);
    }
	}

  componentDidMount() {
    const categoriesDisplayed = [];
    for (var key of Object.keys(categories)) {
      if (!key.includes(".")) {
        categoriesDisplayed.push(key)
      }   
    }
    const queryParameters = new URLSearchParams(window.location.search)
    const category = queryParameters.get("category")
    if (category !== null) {
      this.setState({activeTabIndex:3, homeMode:3, tabValue:3})
      if (category in categoriesUrlLookup) {
        this.setState({currentCategory:categoriesUrlLookup[category] })
        this.retrieveFamilyCategories(categoriesUrlLookup[category])
      } else {
        this.props.history.push('/?category=all')
      }      
    }
    this.setState({ categoriesDisplayed: categoriesDisplayed });

    // console.log(this.props.audioSearchList)
    // console.log(this.props.audiolibrary)
    // this.setState({combinedAudio:[...this.props.audioSearchList,...this.props.audiolibrary]})
    // this.setState({combinedAudio:this.props.audiolibrary})
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (this.state.currentCategory !== 'all' && this.state.parentCategories.length == 0 && this.state.childrenCategories.length == 0) {
      this.retrieveFamilyCategories(this.state.currentCategory)
      scroll.scrollTo(0);
    }
    if (this.state.currentCategory !== 'all' && this.state.wordsList.length == 0 && categories[this.state.currentCategory].links.length>0) {
      this.retrieveFamilyCategories(this.state.currentCategory)
      scroll.scrollTo(0);
    }

    if (this.state.useExample !== prevState.useExample || this.state.useConversational !== prevState.useConversational || this.state.usePhrasebook !== prevState.usePhrasebook) {
      this.onChangeSearch(true, 2, null,this.state.search)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll)
  }

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    this.setState({
      Yposition: winScroll,
    })
  }

  setHistory = (num) => {
    this.setState({
      prevSearch:num[0],
      prevWordsList:num[1],
    })
  }

  resetAll = (e,data) => {
  	this.setState({
  		search:"",
      // newSearchList:[],
      searchWord:"",
  	})
  }



		returnTitle = (word) => {
			let newword = word.split(' | ');
			let fulllist = [];
			for (let i = 0; i < newword.length; i++) {
				if (newword[i].includes('(‐)')) {
					fulllist.push(newword[i].split('(‐)')[0])
					fulllist.push(newword[i].split('(‐)')[0]+'‐')
				} else {
					fulllist.push(newword[i])
				}
			}
			return fulllist
		}

		returnDefinition = (word) => {
			let newword = word.split(' | ')
			return newword
		}
    tlingitNormalize = (tlingit) => {
      tlingit = tlingit.replaceAll('Á','Á').replaceAll('Á','Á')
      tlingit = tlingit.replaceAll('É','É').replaceAll('É','É')
      tlingit = tlingit.replaceAll('Í','Í').replaceAll('Í','Í')
      tlingit = tlingit.replaceAll('Ó','Ó').replaceAll('Ó','Ó')
      tlingit = tlingit.replaceAll('Ú','Ú').replaceAll('Ú','Ú')
      tlingit = tlingit.replaceAll('á','á').replaceAll('á','á')
      tlingit = tlingit.replaceAll('é','é').replaceAll('é','é')
      tlingit = tlingit.replaceAll('í','í').replaceAll('í','í')
      tlingit = tlingit.replaceAll('ó','ó').replaceAll('ó','ó')
      tlingit = tlingit.replaceAll('ú','ú').replaceAll('ú','ú')
      tlingit = tlingit.replaceAll('ʼ',"'")
      tlingit = tlingit.replaceAll('̱','̲')
      tlingit = tlingit.replaceAll('Ḵ','K̲')
      tlingit = tlingit.replaceAll('ḵ','k̲')
      return tlingit
    }

    returnCorrectAudioLibrary = () => {
        if (this.state.useExample && this.state.useConversational && this.state.usePhrasebook) {
          return this.props.audiolibrary
        } else if (this.state.useExample && this.state.useConversational ) {
          return this.props.oneandtwo
        } else if (this.state.useConversational && this.state.usePhrasebook ) {
          return this.props.twoandthree
        } else if (this.state.useExample && this.state.usePhrasebook ) {
          return this.props.oneandthree
        } else if (this.state.useExample) {
          return this.props.examplesentenceaudio1
        } else if (this.state.useConversational) {
          return this.props.conversational2
        } else if (this.state.usePhrasebook) {
          return this.props.phrasebookaudio3
        } else { // edge case
          return this.props.audiolibrary
        }
    }

  	onChangeSearch = (bool,homeMode,event,data) => {
      // console.log(bool,homeMode, event,data)
      let word
      if (bool) {
        word = data
      } else {
        word = data.value
        homeMode = this.state.homeMode
      }

	    let new_search
      let wordsList
      word = this.tlingitNormalize(word)
      new_search = word.replaceAll('ġ','g').replaceAll('s/he','').replaceAll('O-S-ø-','').replaceAll('S-ø-','').replaceAll(' (Ø act)','').replaceAll(' (Ø state)','')

      // console.log(word, new_search, homeMode)
	    if (homeMode === 0) {
        wordsList = fuzzysort.go(new_search, this.props.dictionarySearchList, options1)
      } else if (homeMode === 1) {
        // wordsList = fuzzysort.go(new_search, this.props.dictionarySearchList, options2)
	    } else if (homeMode === 2) {
        // console.log(wordsList)
        wordsList = fuzzysort.go(new_search, this.returnCorrectAudioLibrary(), options3)
	    }


	    this.setState({ wordsList: wordsList, search: word,  activeAudioIndex: {} },()=>{this.setState({audioFilterChangeable:true})});
  	}

  	processStyledText = (sentence) => {
      sentence = sentence.replaceAll("???","...")
      sentence = sentence.replaceAll("⟨","").replaceAll("⟩","").replaceAll("«",'"').replaceAll("»",'"')

      return <span>{sentence}</span>

      // let matches = sentence.match(/⎡.*?⎤/g)
      // let matches1 = sentence.match(/{.*?}/g)
      // let matches2 = sentence.match(/_.*?_/g)
      // // console.log(matches2)
      // if (matches !== null || matches1 !== null || matches2 !== null) {
      //   if (matches !== null) {
      //     matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))           
      //   }
      //   if (matches1 !== null) {
      //     matches1.map((m) => sentence = sentence.replaceAll(m,m.slice(1,-1)))            
      //   }
      //   if (matches2 !== null) {
      //     matches2.map((m) => sentence = sentence.replace(m,'<sub>'+m.slice(1,-1)+'</sub>'))            
      //   }
      //   return <span dangerouslySetInnerHTML={{__html: sentence}} />    
      // } else {
      //   return <span>{sentence}</span>
      // }


  	}


    processStyledTextLink = (sentence) => {
      sentence = sentence.replaceAll(">","")


      sentence = sentence.replaceAll("⟨","").replaceAll("⟩","").replaceAll("↞","").replaceAll("&","").replaceAll("↠","")
      let matches = sentence.match(/⎡.*?⎤/g)
      let matches1 = sentence.match(/{.*?}/g)
      let matches2 = sentence.match(/_.*?_/g)
      // console.log(matches2)
      if (matches !== null || matches1 !== null || matches2 !== null) {
        if (matches !== null) {
          matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))           
        }
        if (matches1 !== null) {
          matches1.map((m) => sentence = sentence.replaceAll(m,m.slice(1,-1)))            
        }
        if (matches2 !== null) {
          matches2.map((m) => sentence = sentence.replace(m,'<sub>'+m.slice(1,-1)+'</sub>'))            
        }
        return <span style={{whiteSpace:'nowrap'}} dangerouslySetInnerHTML={{__html: sentence}} />    
      } else {
        return <span style={{whiteSpace:'nowrap'}}>{sentence}</span>
      }


    }

  	processUsageDefinition = (definition, tag, ind) => {
  		// console.log(definition)
			let matches = definition.match(/<.*?>/g)

			if (matches !== null) {
				matches.map((m) => definition = definition.replaceAll(m,"<i>"+m.slice(1,-1)+'</i>'))				
			}
			let matches1 = definition.match(/⎡.*?⎤/g)
			if (matches1 !== null) {
				matches1.map((m) => definition = definition.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))				
			}

      let matches2 = definition.match(/_.*?_/g)
      if (matches2 !== null) {
        matches2.map((m) => definition = definition.replaceAll(m,'<sub>'+m.slice(1,-1)+'</sub>'))            
      }

      let matches3 = definition.match(/{.*?}/g)
      if (matches3 !== null) {
        matches3.map((m) => definition = definition.replaceAll(m,m.slice(1,-1)))            
      }

			if (tag !== 'noun') {
				definition = '<i>s/he/it is </i>'+definition
			}

			definition = definition.replaceAll("⟨","").replaceAll("⟩","")

  		return <span dangerouslySetInnerHTML={{__html: definition}} />
  	}		


  	processAudioDefinition = (sentence) => {			
      // console.log(sentence)
			let matches = sentence.match(/⎡.*?⎤/g)
			if (matches !== null) {
				matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))				
			}
			sentence = sentence.replaceAll('>','')
			// console.log(sentence)
			// var dom = document.createElement('span');
			// dom.innerHTML = sentence;
  		return <span dangerouslySetInnerHTML={{__html: sentence}} />
  	}


  repeatAudio(audio, event, data) {
    // console.log(audio, event, data)
    // var a = new Audio(API_URL+'static/WoW-Link.mp3');
    // a.play();
    // let a;
    // axios
    //   .get(API_URL + "/audiolibrary/" + audio.mp3)
    //   .then(response => {
    //     // let end = now();
    //     // ReactGA.timing({
    //     //   category: 'Loading',
    //     //   variable: 'dictionary',
    //     //   value: (end-start).toFixed(3),
    //     //   label: 'Dictionary loading'
    //     // });
    //     var a = new Audio(response.data);
    //     console.log(a)
    //     a.play();
    //     // fuse.setCollection(dictionary);
    //     // fuse1.setCollection(dictionary);
    //     // console.log('Fetched dictionary');

    //     // dictionary.forEach(entry => dictionary_dict[entry.keyString] = entry.definitionString) // create dictionary_dict dictionary
    //     // // dictionary_prepared = fuzzysort.prepare(dictionary)

    //     // this.setState({ dictionary: dictionary });
    //   });
    if (!this.state.playingAudio) {

      let sound = new Audio(API_URL + "/tlingitaudiolibrary/" + audio);
      this.setState({playingAudio: true});

      sound.play()

      sound.onended=()=>{
        this.setState({playingAudio: false});
      }
    }
  }

  // soundDone = () => {
  //   console.log('done!')
  // }

  setActiveAudioIndex = (word, index) => {
    // console.log(word, index)
    let activeAudioIndex = this.state.activeAudioIndex
    if (index in activeAudioIndex) {
      delete activeAudioIndex[index]
      this.setState({activeAudioIndex:activeAudioIndex})
    } else {
      // activeAudioIndex[index] = ""
      // this.setState({activeAudioIndex:activeAudioIndex})
      // console.log(word)

      axios
        .get(API_URL + "/tlingitaudiolibraryword/" + word.obj.key)
        .then(response => {
          // let end = now();
          // ReactGA.timing({
          //   category: 'Loading',
          //   variable: 'dictionary',
          //   value: (end-start).toFixed(3),
          //   label: 'Dictionary loading'
          // });
          // audiolibrary = response.data;
          // fuse.setCollection(dictionary);
          // fuse1.setCollection(dictionary);
          activeAudioIndex[index] = response.data
          this.setState({activeAudioIndex:activeAudioIndex})
          // console.log(response.data);
          // activeAudioIndex[index] = response.data
          // this.setState({activeAudioIndex:activeAudioIndex})

          // dictionary.forEach(entry => dictionary_dict[entry.keyString] = entry.definitionString) // create dictionary_dict dictionary
          // dictionary_prepared = fuzzysort.prepare(dictionary)

          // this.setState({ audiolibrary: audiolibrary });
        });


    }
    // console.log(activeAudioIndex)
    // this.setState({activeAudioIndex:activeAudioIndex})
  }



  handleTabChange = (e,data) => {
    // console.log(data.activeIndex, this.state.activeIndex)
    if (data.activeIndex == this.state.activeTabIndex) {
      this.resetAll()
    }

    if (data.activeIndex === 0 && this.state.activeTabIndex !== 0) {
      this.setState({wordsList: [], homeMode: data.activeIndex, activeTabIndex:0})
      this.onChangeSearch(true, 0, null,this.state.search)
      this.props.history.push('/')
    } else if (data.activeIndex === 1 && this.state.activeTabIndex !== 1) {
      // if (!this.state.yugtunAnalyzer) {
      this.setState({wordsList: [], homeMode: data.activeIndex, activeTabIndex:1});                     
      this.onChangeSearch(true, 1, null,this.state.search)
      this.props.history.push('/')
      // this.inputClicked()
      // }
    } else if (data.activeIndex === 2 && this.state.activeTabIndex !== 2) {
      // if (!this.state.yugtunAnalyzer) {
      this.setState({wordsList: [], homeMode: data.activeIndex, activeTabIndex:2});                     
      this.onChangeSearch(true, 2, null,this.state.search)
      this.props.history.push('/')
      // this.inputClicked()
      // }
    }else if (data.activeIndex === 3 && this.state.activeTabIndex !== 3) {
      // if (!this.state.yugtunAnalyzer) {
      this.setState({wordsList: [], homeMode: data.activeIndex, activeTabIndex:3});   
      this.props.history.push('/?category=all')                  
      // this.onChangeSearch(true, 3, null,this.state.search)
      // this.inputClicked()
      // }
    }
  }



  handleTabChange2 = (e, {value}) => {

    if (value == this.state.tabValue) {
      this.resetAll()
    }

    if (value === 0 && this.state.tabValue !== 0) {
      this.setState({wordsList: [], homeMode: value, activeTabIndex:0})
      this.onChangeSearch(true, 0, null,this.state.search)
      this.props.history.push('/')
    } else if (value === 1 && this.state.tabValue !== 1) {
      this.setState({wordsList: [], homeMode: value, activeTabIndex:1});                     
      this.onChangeSearch(true, 1, null,this.state.search)
      this.props.history.push('/')
    } else if (value === 2 && this.state.tabValue !== 2) {
      this.setState({wordsList: [], homeMode: value, activeTabIndex:2});                     
      this.onChangeSearch(true, 2, null,this.state.search)
      this.props.history.push('/')
    } else if (value === 3 && this.state.tabValue !== 3) {
      this.setState({wordsList: [], homeMode: value, activeTabIndex:3});     
      this.props.history.push('/?category=all')                
      // this.onChangeSearch(true, 3, null,this.state.search)
    }
  	this.setState({tabValue: value})
  }

  // randomizeSentence = (direction) => {
  //   if (direction === '+') {
  //     if (this.state.randomSentences.length-1 === this.state.randomSentenceIndex) {
  //       this.setState({randomSentenceIndex: 0})        
  //     } else {
  //       this.setState({randomSentenceIndex: this.state.randomSentenceIndex+1})        
  //     }
  //   } else {
  //     this.setState({randomSentenceIndex: this.state.randomSentenceIndex-1})
  //   }
  // }

  // newUsageBaseDisplay = (word) => {
  //   // console.log(word)

  //   let returnNewUsageBaseDisplayLabel = {
  //     'verb phrase':[
  //       'present',
  //       'past',
  //       'question',
  //       'polite command',
  //       'command',
  //     ],
  //     'noun':[
  //       'stem',
  //       'possessive',
  //       'locative noun case',
  //       'with pronoun',
  //     ],
  //   }
  //   return  <List.Item>
  //             <List.Content style={{fontSize:'16px',paddingTop:'8px',paddingBottom:'10px'}}><span style={{paddingRight:'20px',fontWeight:'500'}}>{word.url.replaceAll(',',', ')}</span><span style={{paddingRight:'15px',fontStyle:'italic'}}>{this.processStyledText(word.thisDefinition)}</span><span style={{'marginLeft':'15px',marginRight:'6px',cursor:'pointer'}}><TagColors tags={word.t} width={window.innerWidth} /></span></List.Content>
  //             {word.newUsageDefinitions.map((u,uindex)=>
  //             <List.Content style={{paddingLeft:'20px'}}>
  //               <Link to={{pathname: '/wordbuilder/' + word.url, state: {initBackendCall:word.backendCalls[uindex], usagedictionary_dict: this.props.usagedictionary_dict, activeKeyInEditIndex:0, value1: 's31-1(1)',value2: 'o31-3(2)', nounvalue1: '1', nounvalue2: '00(3)', fullDefinition: word.fullDefinition, num: word.num, usageDefinition: word.usageDefinition, from: this.props.location.pathname, history:[this.state.search,this.state.wordsList, this.state.Yposition, this.state.activeTabIndex] }}}>
  //                 <List.Content floated='right'>
  //                   <Icon circular style={{marginTop:'3px', color:'#8f8f8f'}} size='small' name='chevron right' />
  //                 </List.Content>
  //               </Link>
  //               <List.Content style={{display:'flex',fontSize:'16px',paddingBottom:'8px',fontFamily:customFontFam,lineHeight:'25px'}}>
  //                 <span style={{flex:2,'paddingRight':'3px',fontWeight:'500'}}>
  //                 <Link style={{borderBottom:'1px solid #858585',paddingBottom:'1px',color:'#306190'}} to={{pathname: '/wordbuilder/' + word.url, state: {initBackendCall:word.backendCalls[uindex], activeKeyInEditIndex:0, value1: 's31-1(1)',value2: 'o31-3(2)', nounvalue1: '1', nounvalue2: '00(3)', fullDefinition: word.fullDefinition, num: word.num, usageDefinition: word.usageDefinition, from: this.props.location.pathname, history:[this.state.search,this.state.wordsList, this.state.Yposition, this.state.activeTabIndex] }}}>
  //                 {this.processStyledTextLink(word.usageWords[uindex])}
  //                 </Link>
  //                 </span>
  //                 <span style={{flex:5,fontSize:'16px',color:'#000000cc',fontWeight:'400',lineHeight:'23px',paddingTop:'4px'}}>{word.newUsageDefinitions[uindex]}</span>
  //                 <span style={{flex:3,color:'#BEBEBE',fontStyle:'italic',textAlign:'right'}}>{returnNewUsageBaseDisplayLabel[word.t][uindex]}</span>
  //               </List.Content>
  //             </List.Content>
  //             )}
  //           </List.Item>

  //   // return <List.Item>
  //   // <Link to={{pathname: '/wordbuilder/' + word.url, state: {initBackendCall:word.backendCalls[0], activeKeyInEditIndex:0, value1: 's31-1(1)',value2: 'o31-3(2)', nounvalue1: '1', nounvalue2: '00(3)', fullDefinition: word.fullDefinition, word: word.usageWord, num: word.num, usageDefinition: word.usageDefinition, from: this.props.location.pathname, history:[this.state.search,this.state.wordsList, this.state.Yposition, this.state.activeTabIndex] }}}>
  //   //   <List.Content floated='right'>
  //   //     <Icon circular style={{marginTop:'8px', color:'#8f8f8f'}} size='large' name='chevron right' />
  //   //   </List.Content>
  //   // </Link>
  //   //   <List.Content>
  //   //     <List.Header style={{fontSize:'16px',paddingBottom:'4px',fontFamily:customFontFam,lineHeight:'25px'}}>
  //   //       <div> 
  //   //       <span style={{'paddingRight':'3px',fontWeight:'500'}}>
  //   //       <Link style={{borderBottom:'1px solid #858585',paddingBottom:'1px',color:'#306190'}} to={{pathname: '/wordbuilder/' + word.url, state: {initBackendCall:word.backendCalls[0], activeKeyInEditIndex:0, value1: 's31-1(1)',value2: 'o31-3(2)', nounvalue1: '1', nounvalue2: '00(3)', fullDefinition: word.fullDefinition, word: word.usageWord, num: word.num, usageDefinition: word.usageDefinition, from: this.props.location.pathname, history:[this.state.search,this.state.wordsList, this.state.Yposition, this.state.activeTabIndex] }}}>
  //   //       {this.processStyledText(word.usageWord)}
  //   //       </Link>
  //   //       </span>
  //   //       <span style={{'marginLeft':'15px',marginRight:'6px',cursor:'pointer'}}>  
  //   //         <TagColors tags={word.t} width={window.innerWidth} />
  //   //       </span>
  //   //       </div>
  //   //     </List.Header>
  //   //     <List.Description style={{fontSize:'16px',color:'#000000cc',fontWeight:'400',lineHeight:'23px',paddingTop:'4px'}}>{this.processUsageDefinition(word.usageDefinition,word.t,i)}</List.Description>
  //   //   </List.Content>
  //   // </List.Item>
  // }

  spanParses = (spanList,fontSize,fontWeight) => {
    return spanList.map((t,tindex)=>{
      let entries = [...t[1],...t[2]]
      let maxLength = (entries.length % 2 == 0 ? entries.length/2 : (entries.length+1)/2)

      if (entries.length == 0) {

        return <span style={{marginBottom:'2px',marginTop:'2px',paddingBottom:'1px',marginRight:'6px',fontSize:fontSize, fontWeight:fontWeight, borderBottom:'2px dotted white'}}>
          {this.processStyledText(t[0])}
        </span>

      } else {

        return <Popup hideOnScroll on='click' position='bottom center' onClose={()=>{this.setState({parseIndex:0})}} content={
          <div style={{fontFamily:customFontFam, fontSize:'16px'}}>
            <div style={{textAlign:'center',fontStyle:'italic',color:'#aeaeae',margin:3}}>Possible Entries</div>
            {entries.map((k,kindex)=>{
              if (kindex == this.state.parseIndex*2 || kindex == this.state.parseIndex*2+1) {
              return <div style={{marginTop:15,marginBottom:10}}>
                  <div style={{display:'flex',alignItems:'center',flexDirection:'row',fontWeight:'500'}}>
                        {k['url'].length === 0 ?
                          <span style={{whiteSpace:'nowrap'}}>{k['tlingit']}</span>
                          :
                          <a style={{whiteSpace:'nowrap'}} href={"https://www.haashuka.com/"+k['url']} target="_blank">{k['tlingit']}</a>
                        }
                        {k['tags'].map((t,tindex)=>
                            (tindex === 0 && t.length != 0 ?
                        <span style={{display:'flex',marginLeft:'12px'}}>
                          <TagColors homeMode={0} tags={t} width={window.innerWidth} />
                        </span>
                        :
                        null
                        )
                      )}
                  </div>
                  <div style={{fontStyle:'italic',marginTop:'5px'}}>{k['english']}</div>
              </div>
              }})}

            {this.state.parseIndex+1 < maxLength+1 && maxLength > 1 ?
              <div style={{display:'flex',justifyContent:'center',marginTop:'12px'}}>
              <Icon disabled={this.state.parseIndex === 0} circular onClick={() => this.setState({parseIndex: this.state.parseIndex-1})} style={{margin:0,color:'#B1B1B1',cursor:'pointer',fontSize:'15px'}}  name='chevron left' />
              <Label basic circular style={{width:30,color:'#aeaeae',fontWeight:500,height:30,fontSize:14,}}>
                {this.state.parseIndex + 1}
              </Label>
              <Icon disabled={this.state.parseIndex+1 == maxLength} circular onClick={() => this.setState({parseIndex: this.state.parseIndex+1})} style={{margin:0,color:'#B1B1B1',cursor:'pointer',fontSize:'15px'}}  name='chevron right' />
              </div>  
              :
              null
            }      
          </div>
        } trigger={
          <span style={{cursor:'pointer', marginBottom:'2px',marginTop:'2px',paddingBottom:'1px',marginRight:'6px',fontSize:fontSize, fontWeight:fontWeight, borderBottom:'2px dotted #b5b5b5'}}>
            {this.processStyledText(t[0])}
          </span>
        } />

      }
    })
  }



  underlineFixer = (word) => {
    // console.log(word)
    word = word.replaceAll('g</b>̲','g̲</b>').replaceAll('G</b>̲','G̲</b>').replaceAll('x</b>̲','x̲</b>').replaceAll('X</b>̲','X̲</b>').replaceAll('k</b>̲','k̲</b>').replaceAll('K</b>̲','K̲</b>')
    word = word.replaceAll('g<b>̲</b>','g̲<b></b>').replaceAll('G<b>̲</b>','G̲<b></b>').replaceAll('x<b>̲</b>','x̲<b></b>').replaceAll('X<b>̲</b>','X̲<b></b>').replaceAll('k<b>̲</b>','k̲<b></b>').replaceAll('K<b>̲</b>','K̲<b></b>')
    word = word.replaceAll('g<b>̲','g̲<b>').replaceAll('G<b>̲','G̲<b>').replaceAll('x<b>̲','x̲<b>').replaceAll('X<b>̲','X̲<b>').replaceAll('k<b>̲','k̲<b>').replaceAll('K<b>̲','K̲<b>')
    word = word.replaceAll('a</b>́','á</b>').replaceAll('e</b>́','é</b>').replaceAll('i</b>́','í</b>').replaceAll('o</b>́','ó</b>').replaceAll('u</b>́','ú</b>')
    word = word.replaceAll('a<b>́</b>','á<b></b>').replaceAll('e<b>́</b>','é<b></b>').replaceAll('i<b>́</b>','í<b></b>').replaceAll('o<b>́</b>','ó<b></b>').replaceAll('u<b>́</b>','ú<b></b>')
    word = word.replaceAll('a<b>́','á<b>').replaceAll('e<b>́','é<b>').replaceAll('i<b>́','í<b>').replaceAll('o<b>́','ó<b>').replaceAll('u<b>́','ú<b>')
    word = word.replaceAll('A</b>́','Á</b>').replaceAll('E</b>́','É</b>').replaceAll('I</b>́','Í</b>').replaceAll('O</b>́','Ó</b>').replaceAll('U</b>́','Ú</b>')
    word = word.replaceAll('A<b>́</b>','Á<b></b>').replaceAll('E<b>́</b>','É<b></b>').replaceAll('I<b>́</b>','Í<b></b>').replaceAll('O<b>́</b>','Ó<b></b>').replaceAll('U<b>́</b>','Ú<b></b>')
    word = word.replaceAll('A<b>́','Á<b>').replaceAll('E<b>́','É<b>').replaceAll('I<b>́','Í<b>').replaceAll('O<b>́','Ó<b>').replaceAll('U<b>́','Ú<b>')
    return word
  }
  buttonGroupKeyboard = (homeMode) => {
    return  <Button.Group basic>
              <Button onClick={()=>{this.handleRef.current.focus(); this.onChangeSearch(true,homeMode,null,this.state.search+'g̲')}} style={letterbutton}>g̲</Button>
              <Button onClick={()=>{this.handleRef.current.focus(); this.onChangeSearch(true,homeMode,null,this.state.search+'k̲')}} style={letterbutton}>k̲</Button>
              <Button onClick={()=>{this.handleRef.current.focus(); this.onChangeSearch(true,homeMode,null,this.state.search+'x̲')}} style={letterbutton}>x̲</Button>
              <Button onClick={()=>{this.handleRef.current.focus(); this.onChangeSearch(true,homeMode,null,this.state.search+'á')}} style={letterbutton}>á</Button>
              <Button onClick={()=>{this.handleRef.current.focus(); this.onChangeSearch(true,homeMode,null,this.state.search+'é')}} style={letterbutton}>é</Button>
              <Button onClick={()=>{this.handleRef.current.focus(); this.onChangeSearch(true,homeMode,null,this.state.search+'í')}} style={letterbutton}>í</Button>
              <Button onClick={()=>{this.handleRef.current.focus(); this.onChangeSearch(true,homeMode,null,this.state.search+'ó')}} style={letterbutton}>ó</Button>
              <Button onClick={()=>{this.handleRef.current.focus(); this.onChangeSearch(true,homeMode,null,this.state.search+'ú')}} style={letterbutton}>ú</Button>
            </Button.Group>
  }

  retrieveFamilyCategories(j) {
  // console.log(categories[j])
  // const children = [];
  let parent = [];
  // for (let i = 1; i < categories[j].children + 1; i++) {
  //   children.push(j+'.'+i.toString())
  // }
  let wordsList = []

  if (this.state.homeMode === 3 && Object.keys(this.props.urlDictionary).length > 0) {
    categories[j].links.map((k)=>{
      // console.log(k)
      // console.log(Object.keys(this.props.urlDictionary).length)
      wordsList.push({'obj':{'dialect':this.props.urlDictionary[k].dialect,'soundfile':this.props.urlDictionary[k].soundfile,'url':this.props.urlDictionary[k].url, 'pos':this.props.urlDictionary[k].pos, 'lexicon':this.props.urlDictionary[k].lexicon, 'gloss':this.props.urlDictionary[k].gloss}})
    })
    
    this.props.history.push('/?category='+categories[j].url)
    // console.log(j)
    // if (j.includes('.')) {
    //   var p = j.split('.');
    //   for (let k = 1; k < p.length; k++) {
    //     parents.push((p.slice(0,k)).join('.'))
    //   }
    // }

    if ('parent' in categories[j]) {
      parent = categories[j].parent
    }

    this.setState({ 
      currentCategory: j,
      childrenCategories: categories[j].children,
      parentCategories: parent,
      // parentCategories: parents,
      wordsList:wordsList,
      });    
  }

}

  isLetter(str) {
    return str.length === 1 && str.match(/[a-z]/i);
  }

	render() {
    // console.log(this.state)
		let isCommon = false
    let displayList = (this.state.search.length >= 2 && this.state.wordsList.length > 0) || (this.state.homeMode === 3 && this.state.wordsList.length > 0);
    let wordsList = this.state.wordsList;
    let emptyList = this.state.search.length >= 2 && this.state.wordsList.length === 0 && this.state.activeTabIndex !== 3;
    let categoryMargin = '4px'
    // let ranDem = this.state.randomSentences[this.state.randomSentenceIndex]
    // wordsList.map((k)=>{
    //   console.log(k)
    //   k = fuzzysort.highlight(k[0], '<b>', '</b>')
    //   console.log(k)
    // })

  const panes = [
    {
      menuItem: (
      	<Menu.Item style={customFontFam2}>
      	<div><span>Dictionary Entry</span> </div>
      	</Menu.Item>
      	),
    },
    {
      menuItem: (<div />),
    },
    {
      menuItem: (
      	<Menu.Item style={customFontFam2}>
      	<div><span>Audio Clip</span> </div>
      	</Menu.Item>
      	),
    },
    {
      menuItem: (
        <Menu.Item style={customFontFam2}>
        <div><span>Category</span> </div>
        </Menu.Item>
        ),
    },
    ];

		return (
			<div className="app" style={{fontFamily:customFontFam}}>
      <Grid textAlign='center' style={{ height: window.innerHeight/1.5 }} verticalAlign={this.state.searchBarStuckTop ? 'top' : 'top'}>
      <Grid.Row style={{paddingTop:(window.innerWidth < 480 ? 0 : 10)}}>
      <Grid.Column style={{ maxWidth: 800, padding: (window.innerWidth < 480 ? 0 : 10) }} textAlign='left'>
         

          <Image style={{'fontSize': '2.3em',cursor:'pointer',borderTopLeftRadius:'8px',borderTopRightRadius:'8px'}} src={"/newbanner.jpeg"}/>          
          
          <Grid style={{fontFamily:customTitleFontFam}}>
          {window.innerWidth < 480 ?
          <Grid.Row columns={2}>
              <Grid.Column style={{display:'flex',borderBottomLeftRadius:'8px',borderBottomRightRadius:'8px',alignItems:'center',marginLeft:14,marginRight:14,backgroundColor:'black'}} width={16}>
              <div style={{lineHeight:'26px',marginTop:'5px',marginBottom:'5px'}}>
{/*              <div onClick={this.resetAll} style={{ color: 'white', fontSize:'13pt', fontWeight:'600', cursor:'pointer'}}>Dictionary of Tlingit</div>
*/}              <div onClick={this.resetAll} style={{ color: 'white', fontSize:'13pt', fontWeight:'600', cursor:'pointer'}}>Haa Shuká Yóo X̲'atángi (Dictionary)</div>
              <div style={{color:'white',fontStyle:'italic',fontSize:'12pt',paddingBottom:'1px'}}>Language of our ancestors before and after</div>
              </div>
            </Grid.Column>
          </Grid.Row>
            :
          (this.state.logoup  ?
          <Grid.Row style={{margin:10}} columns={2}>
            <Grid.Column width={3}>
            <Image onClick={this.resetAll} style={{'fontSize': '2.3em',cursor:'pointer'}} src={"/image000001.jpeg"}/>          
            </Grid.Column>
              <Grid.Column style={{display:'flex',alignItems:'center'}} width={13}>
              <div style={{lineHeight:'30px'}}>
              <div onClick={this.resetAll} style={{ color: 'black', fontSize:'17pt', fontWeight:'500', cursor:'pointer'}}>Dictionary of Tlingit (In-Development)</div>
              </div>
            </Grid.Column>
          </Grid.Row>
          :
          <Grid.Row columns={2}>
              <Grid.Column style={{display:'flex',borderBottomLeftRadius:'8px',borderBottomRightRadius:'8px',alignItems:'center',marginLeft:14,marginRight:14,paddingBottom:'2px',paddingTop:'2px',marginBottom:'4px',backgroundColor:'black'}} width={16}>
              <div style={{lineHeight:'34px'}}>
              <div onClick={this.resetAll} style={{ color: 'white', fontSize:'17pt', fontWeight:'500', cursor:'pointer'}}>Haa Shuká Yóo X̲'atángi (Dictionary of Tlingit)</div>
              <div style={{color:'white',fontStyle:'italic',fontSize:'15pt',paddingBottom:'1px'}}>Language of our ancestors before and after</div>
              </div>
            </Grid.Column>
          </Grid.Row>
          )
          }
          </Grid>
            {false ?
            <div style={{display:'flex',justifyContent:'center',marginBottom:'35px'}} />
            :
            <List style={{display:'flex',alignItems:'center',justifyContent:'center',padding:'5px',paddingTop:'10px',paddingBottom:'8px',marginBottom:'20px',backgroundColor:'white'}} divided horizontal>
              <List.Item><Link to={{pathname: '/map'}}><span style={{fontSize:(window.innerWidth < 600 ? '14px':'16px'),lineHeight:'18px'}}>T'aak̲ú Áak'w K̲wáan Place Names</span></Link></List.Item>
              <List.Item><Link to={{pathname: '/phrasebook/1'}}><span style={{fontSize:(window.innerWidth < 600 ? '14px':'16px'),lineHeight:'18px'}}>Say It In Tlingit! Phrasebook</span></Link></List.Item>
              <List.Item><Link to={{pathname: '/dialogues/1'}}><span style={{fontSize:(window.innerWidth < 600 ? '14px':'16px'),lineHeight:'18px'}}>Dialogue Lessons</span></Link></List.Item>
            </List>
          }
        <Container ref={this.search_container} className='search_container'>
          <div style={{fontFamily:customTitleFontFam,marginBottom:10,fontSize:'17px',fontWeight:'700'}}>Search by:</div>
					<Grid stackable>
					{window.innerWidth < 600 ?
						<Dropdown className='frontpage' style={{fontFamily:customTitleFontFam}} value={this.state.tabValue} onChange={this.handleTabChange2} options={tabOptions} />
						:
          	<Tab style={{paddingTop:10}} activeIndex={this.state.activeTabIndex} menu={{ secondary: true, pointing: true, size:'huge' }} panes={panes} onTabChange={this.handleTabChange} />
          }


			{this.state.homeMode === 0 ? 
					<Grid.Row>
              <Grid.Column style={{ flex: 1, paddingTop:0 }}>
					<div className='header-text'>
		              	<Input
		                ref={this.handleRef}
		                placeholder='Search a phrase...'
		                // action={{ icon: (this.state.search > 0 ? 'x': null), transparent:true,size:'huge', onClick: () => {this.setState({search:''})}}}
		                icon={this.state.search.length > 0 ? <Icon name='x' color='grey' onClick={()=>{this.setState({search:''})}} link /> : null}
		                // iconPosition='right'
		                size='huge'
		                onChange={this.onChangeSearch.bind(this,false,0)}
                    spellcheck="false"
		                value={this.state.search}
		                // onKeyPress={this.onKeyPress}
		                fluid>

		                </Input> 
					</div>

				  <div style={{textAlign:'center',marginTop:'3px'}}>
            {this.buttonGroupKeyboard(0)}
				  </div>
					</Grid.Column>
				</Grid.Row>
				:
				null
			}


        {this.state.homeMode === 1 ? 
          <Grid.Row>
              <Grid.Column style={{ flex: 1, paddingTop:0 }}>
          <div className='header-text'>
                    <Input
                    ref={this.handleRef}
                    placeholder='Search a word...'
                    // action={{ icon: (this.state.wordBuilderMode ? 'search' : null), transparent:true,size:'huge', onClick: () => 
                    // {this.state.wordBuilderMode ? this.inputClicked() : null}
                    // }}
                    icon={this.state.search.length > 0 ? <Icon name='x' color='grey' onClick={()=>{this.setState({search:''})}} link /> : null}
                    // icon={<Icon name='x' onClick={console.log('hi')} link />}
                    // iconPosition='right'
                    size='huge'
                    onChange={this.onChangeSearch.bind(this,false,1)}
                    value={this.state.search}
                    spellcheck="false"
                    // onKeyPress={this.onKeyPress}
                    fluid>

                    </Input> 
          </div>

          <div style={{textAlign:'center',marginTop:'3px'}}>
            {this.buttonGroupKeyboard(1)}
          </div>

          </Grid.Column>
        </Grid.Row>
        :
        null
      }

			{this.state.homeMode === 2 ? 
            <Grid.Row >
              <Grid.Column style={{ flex: 1, paddingTop:0 }}>
					<div className='header-text'>
              <Input
                ref={this.handleRef}
                placeholder='Search a phrase...'
                // action={{ icon: (this.state.yugtunAnalyzer ? 'search' : null), transparent:true,size:'huge', onClick: () => 
                // {this.state.yugtunAnalyzer ? this.inputClicked() : null}
                // }}
                icon={this.state.search.length > 0 ? <Icon name='x' color='grey' onClick={()=>{this.setState({search:'',activeAudioIndex:{}})}} link /> : null}
                // icon={<Icon name='x' onClick={console.log('hi')} link />}
                // iconPosition='right'
                size='huge'
                spellcheck="false"
                onChange={this.onChangeSearch.bind(this,false,2)}
                value={this.state.search}
                // onKeyPress={this.onKeyPress}
                fluid  />    
					</div>

				  <div style={{textAlign:'center',marginTop:'3px'}}>
            {this.buttonGroupKeyboard(2)}
				  </div>

            {this.state.search.length > 1 ?
              <div>
                <Checkbox checked={this.state.useExample} onClick={()=>{if (!(this.state.useExample && !this.state.useConversational && !this.state.usePhrasebook)) {this.setState({useExample:!this.state.useExample,audioFilterChangeable:false})}}} disabled={this.state.useExample && !this.state.useConversational && !this.state.usePhrasebook} style={{fontFamily:customFontFam, display:'flex',justifyContent:'center',margin:'12px',fontSize:'16px',color:'red'}} label='Show dictionary entry audio' />
                <Checkbox checked={this.state.useConversational} onClick={()=>{if (!(!this.state.useExample && this.state.useConversational && !this.state.usePhrasebook)) {this.setState({useConversational:!this.state.useConversational,audioFilterChangeable:false})}}} disabled={!this.state.useExample && this.state.useConversational && !this.state.usePhrasebook} style={{color:'#8f8f8f',fontFamily:customFontFam, display:'flex',justifyContent:'center',margin:'12px',marginBottom:'0px',fontSize:'16px'}} label='Show conversational audio' />
                <Checkbox checked={this.state.usePhrasebook} onClick={()=>{if (!(!this.state.useExample && !this.state.useConversational && this.state.usePhrasebook)) {this.setState({usePhrasebook:!this.state.usePhrasebook,audioFilterChangeable:false})}}} disabled={this.state.usePhrasebook && !this.state.useExample && !this.state.useConversational} style={{color:'#8f8f8f',fontFamily:customFontFam, display:'flex',justifyContent:'center',margin:'12px',marginBottom:'0px',fontSize:'16px'}} label='Show phrasebook audio' />
              </div>
              :
              null
            }

              </Grid.Column>
            </Grid.Row>
				:
				null
			}


				</Grid>

        {this.state.homeMode === 3 ? 

        (this.state.currentCategory === 'all' ?
          <div style={{fontFamily:customTitleFontFam, fontSize:'18px',lineHeight:'22px',maxWidth:'500px',marginLeft:'20px',paddingTop:'50px'}}>
              <div style={{fontWeight:'500'}}><Icon style={{width:'22px'}} name='triangle down' />{'All Categories'}</div>
            {this.state.categoriesDisplayed.map((j) => (
              <div style={{marginLeft:44,marginTop:categoryMargin}}>
              <span onClick={()=>{this.retrieveFamilyCategories(j);}} style={{fontWeight:'500',cursor:'pointer',color:'#306190'}}>{categories[j].name+(categories[j].links.length>0 ? ' ('+categories[j].links.length+')' : '')}</span>
              </div>                    
            ))}
          </div>
          :
          <div style={{fontFamily:customTitleFontFam,fontSize:'18px',lineHeight:'22px',maxWidth:'500px',marginLeft:'20px',paddingTop:'50px'}}>
                <div onClick={()=>{
                    this.setState({
                      currentCategory:'all',
                      childrenCategories: [],
                      parentCategories: [],
                      wordsList:[],          
                    });
                    this.props.history.push('/?category=all')
                  }} ><span style={{fontWeight:'500',cursor:'pointer',color:'#306190'}}><Icon style={{width:'22px'}} name='triangle right' />{'All Categories'}</span></div>

              {this.state.parentCategories.map((j,jindex) => (
                <div style={{marginLeft:(22*(jindex+1)),marginTop:categoryMargin}}>
                <Icon style={{color:'#306190', width:'22px'}} name='triangle right' /><span onClick={()=>{this.setState({currentCategory:j});this.retrieveFamilyCategories(j);}} style={{fontWeight:'500',marginTop:'3px',cursor:'pointer',color:'#306190'}}>{categories[j].name+(categories[j].links.length>0 ? ' ('+categories[j].links.length+')' : '')}</span>
                </div>                    
              ))}

              <div style={{marginLeft:((this.state.childrenCategories.length > 0 ? 0 : 22)+22*(this.state.parentCategories.length+1)),marginTop:categoryMargin}}>
                <div style={{fontWeight:'500'}}>{this.state.childrenCategories.length > 0 ? <Icon style={{width:'22px'}} name='triangle down' />:null}{categories[this.state.currentCategory].name+(categories[this.state.currentCategory].links.length>0 ? ' ('+categories[this.state.currentCategory].links.length+')' : '')}</div>
              </div>    

            {this.state.childrenCategories.map((j) => (
              j in categories ?
                <div style={{marginLeft:(22+22*(this.state.parentCategories.length+2)),marginTop:categoryMargin}}>
                  <span onClick={()=>{this.retrieveFamilyCategories(j);}} style={{fontWeight:'500',cursor:'pointer',color:'#306190'}}>{categories[j].name+(categories[j].links.length>0 ? ' ('+categories[j].links.length+')' : '')}</span>
                </div>    
               :
               null
            ))}

          </div>
          )
          :
          null
        }

        {wordsList.length > 0 && this.state.homeMode === 3 && this.state.currentCategory !== 'all' ?
          <div style={{textAlign:'center',fontFamily:customFontFam,fontSize:'16px',color:'#777777',paddingTop:'25px',paddingBottom:'10px',fontStyle:'italic'}}>{this.state.wordsList.length+" entries related to '"+(categories[this.state.currentCategory].name)+"'"}</div>
          :
          null
        }

          <Transition visible={displayList} animation='fade' duration={300}>

            <List divided>

              {displayList && this.state.homeMode === 0 ?
              	wordsList.map((word) => 
						    {
                  let strongestHit = -100000000;
                  let bestHitIndex = -1;
                  let bestHitStartIndex = -1
                  Array.from(Array(22).keys()).reverse().map((i)=>{
                    if (word[i] !== null && word[i].score > strongestHit) {
                      strongestHit = word[i].score
                      bestHitIndex = i
                      bestHitStartIndex = Math.floor(i/2)
                    }
                  })

                return <List.Item style={{padding:'5px 1px'}} key={word.obj.url}>
						    <Link to={{pathname: '/' + word.obj.url, state: { word: word.obj.url, from: this.props.location.pathname, history:[this.state.search,this.state.wordsList, this.state.Yposition, this.state.activeTabIndex] }}}>
                  <List.Content floated='right'>
                    <Icon circular style={{marginTop:'4px', color:'#8f8f8f'}} name='chevron right' />
                  </List.Content>
                </Link>
						      <List.Content>
						        <List.Header style={{fontSize:'16px',paddingTop:(word.obj.definition.length == 0 ? '4px':'0px'),paddingBottom:'2px',fontFamily:customFontFam,lineHeight:'25px'}}>
				          		<div style={{display:'flex',alignItems:'center'}}> 
                        <Link style={{color:'#306190'}} to={{pathname: '/' + word.obj.url, state: { word: word.obj.url, from: this.props.location.pathname, history:[this.state.search,this.state.wordsList, this.state.Yposition, this.state.activeTabIndex] }}}>
  				              <span style={{'paddingRight':'3px',fontWeight:'500'}}> {/* ,borderBottom:'1px solid #858585',paddingBottom:'1px', */}
                        {word[0] != null && bestHitIndex == 0 ?
                          <span dangerouslySetInnerHTML={{__html:sanitize(this.underlineFixer(fuzzysort.highlight(word[0],"<b>", '</b>')))}}></span>
                          :
                          this.processStyledTextLink(word.obj.lexicon)
                        }
                        </span>
                        </Link>

                        {'soundfile' in word.obj ?
                          <Icon circular onClick={()=>this.repeatAudio(word.obj.soundfile)} style={{marginLeft:'10px',fontSize:'13px',color:'#8F8F8F',cursor:'pointer'}} name='volume up' />
                          :
                          null
                        }

                        {'dialect' in word.obj ?
                          (word.obj.dialect.map((d,dindex)=>
                            <span style={{'marginLeft':(dindex == 0 ? '12px':'0px'),marginRight:'6px'}}>  
                              <span style={{display:'flex',marginRight:'5px'}}>
                                <TagColors homeMode={this.state.homeMode} tags={d} width={window.innerWidth} />
                              </span>
                            </span>
                          ))
                          :
                          null
                        }
                        <span style={{'marginLeft':('dialect' in word.obj ? '0px':'12px'),marginRight:'6px'}}>  
                          <span style={{display:'flex',marginRight:'5px'}}>
                            <TagColors homeMode={this.state.homeMode} tags={word.obj.pos} width={window.innerWidth} />
                          </span>
                        </span>

				              </div>
						        </List.Header>
						        <List.Description style={{fontSize:'16px',color:'#000000',fontWeight:'400',lineHeight:'23px',paddingTop:'4px'}}>
                    {word[1] != null && bestHitIndex == 1 ?
                      <span dangerouslySetInnerHTML={{__html: sanitize(this.underlineFixer(fuzzysort.highlight(word[1], '<b>', '</b>')))}}></span>
                      :
                      this.processStyledText(word.obj.gloss.join('; '))
                    }
                    </List.Description>
                    {bestHitIndex > 1 && 'verbp'+(bestHitStartIndex).toString()+'t' in word.obj ?
                      <List.Description style={{fontSize:'16px',color:'#000000dd',fontWeight:'400',lineHeight:'23px',paddingTop:'8px',paddingLeft:'20px',fontStyle:'italic'}}>
                      {bestHitIndex % 2 == 0 ?
                        <span dangerouslySetInnerHTML={{__html: sanitize(this.underlineFixer(fuzzysort.highlight(word[bestHitIndex], '<b>', '</b>')))}}></span>
                        :
                        this.processStyledText(word.obj['verbp'+(bestHitStartIndex).toString()+'t'])
                      }
                      </List.Description>
                      :
                      null
                    }
                    {bestHitIndex > 1 && 'verbp'+(bestHitStartIndex).toString()+'e' in word.obj ?
                      <List.Description style={{fontSize:'16px',color:'#000000dd',fontWeight:'400',lineHeight:'23px',paddingTop:'3px',paddingBottom:'4px',paddingLeft:'20px',fontStyle:'italic'}}>
                      {bestHitIndex % 2 == 1 ?
                        <span dangerouslySetInnerHTML={{__html: sanitize(this.underlineFixer(fuzzysort.highlight(word[bestHitIndex], '<b>', '</b>')))}}></span>
                        :
                        this.processStyledText(word.obj['verbp'+(bestHitStartIndex).toString()+'e'])
                      }
                      </List.Description>
                      :
                      null
                    }
						      </List.Content>
						    </List.Item>
						    })
						    :
						    null
              	}

              {displayList && this.state.homeMode === 1 ?
                wordsList.map((word) => 
                {
                  let strongestHit = -100000000;
                  let bestHitIndex = -1;
                  let bestHitStartIndex = -1
                  Array.from(Array(22).keys()).reverse().map((i)=>{
                    if (word[i] !== null && word[i].score > strongestHit) {
                      strongestHit = word[i].score
                      bestHitIndex = i
                      bestHitStartIndex = Math.floor(i/2)
                    }
                  })

                return <List.Item style={{padding:'5px 1px'}} key={word.obj.url}>
                <Link to={{pathname: '/' + word.obj.url, state: { word: word.obj.url, from: this.props.location.pathname, history:[this.state.search,this.state.wordsList, this.state.Yposition, this.state.activeTabIndex] }}}>
                  <List.Content floated='right'>
                    <Icon circular style={{marginTop:'4px', color:'#8f8f8f'}} name='chevron right' />
                  </List.Content>
                </Link>
                  <List.Content>
                    <List.Header style={{fontSize:'16px',paddingTop:(word.obj.definition.length == 0 ? '4px':'0px'),paddingBottom:'2px',fontFamily:customFontFam,lineHeight:'25px'}}>
                          <div style={{display:'flex',alignItems:'center'}}> 
                          <Link style={{color:'#306190'}} to={{pathname: '/' + word.obj.url, state: { word: word.obj.url, from: this.props.location.pathname, history:[this.state.search,this.state.wordsList, this.state.Yposition, this.state.activeTabIndex] }}}>
                          <span style={{'paddingRight':'3px',fontWeight:'500'}}>
                          {word[0] != null && bestHitIndex == 0 ?
                            <span dangerouslySetInnerHTML={{__html: sanitize(this.underlineFixer(fuzzysort.highlight(word[0], '<b>', '</b>')))}}></span>
                            :
                            this.processStyledTextLink(word.obj.theme)
                          }
                          </span>
                          </Link>

                        {'soundfile' in word.obj ?
                          <Icon circular onClick={()=>this.repeatAudio(word.obj.soundfile)} style={{marginLeft:'10px',fontSize:'13px',color:'#8F8F8F',cursor:'pointer'}} name='volume up' />
                          :
                          null
                        }

                        {'dialect' in word.obj ?
                          (word.obj.dialect.map((d,dindex)=>
                            <span style={{'marginLeft':(dindex == 0 ? '12px':'0px'),marginRight:'6px'}}>  
                              <span style={{display:'flex',marginRight:'5px'}}>
                                <TagColors homeMode={this.state.homeMode} tags={d} width={window.innerWidth} />
                              </span>
                            </span>
                          ))
                          :
                          null
                        }
                        <span style={{'marginLeft':('dialect' in word.obj ? '0px':'12px'),marginRight:'6px'}}>  
                          <span style={{display:'flex',marginRight:'5px'}}>
                            <TagColors homeMode={this.state.homeMode} tags={word.obj.pos} width={window.innerWidth} />
                          </span>
                        </span>

                          </div>
                          {word.obj.root.length > 0 ?
                            <div style={{fontSize:'16px',color:'#000000dd',fontWeight:'400'}}>
                              {this.processStyledTextLink(word.obj.root.replaceAll("1","¹").replaceAll("2","²").replaceAll("3","³").replaceAll("4","⁴").replaceAll("5","⁵").replaceAll("6","⁶").replaceAll("7","⁷"))}
                            </div>
                            :
                            null
                          }
                    </List.Header>
                    <List.Description style={{fontSize:'16px',color:'#000000',fontWeight:'400',lineHeight:'23px',paddingTop:'4px'}}>
                    {word[1] != null && bestHitIndex == 1 ?
                      <span dangerouslySetInnerHTML={{__html: sanitize(this.underlineFixer(fuzzysort.highlight(word[1], '<b>', '</b>')))}}></span>
                      :
                      this.processStyledText(word.obj.definition)
                    }
                    </List.Description>
                    {bestHitIndex > 1 && 'verbp'+(bestHitStartIndex).toString()+'t2' in word.obj ?
                      <List.Description style={{fontSize:'16px',color:'#000000dd',fontWeight:'400',lineHeight:'23px',paddingTop:'8px',paddingLeft:'20px',fontStyle:'italic'}}>
                      {bestHitIndex % 2 == 0 ?
                        <span dangerouslySetInnerHTML={{__html: sanitize(this.underlineFixer(fuzzysort.highlight(word[bestHitIndex], '<b>', '</b>')))}}></span>
                        :
                        this.processStyledText(word.obj['verbp'+(bestHitStartIndex).toString()+'t2'])
                      }
                      </List.Description>
                      :
                      null
                    }
                    {bestHitIndex > 1 && 'verbp'+(bestHitStartIndex).toString()+'e2' in word.obj ?
                      <List.Description style={{fontSize:'16px',color:'#000000dd',fontWeight:'400',lineHeight:'23px',paddingTop:'3px',paddingBottom:'4px',paddingLeft:'20px',fontStyle:'italic'}}>
                      {bestHitIndex % 2 == 1 ?
                        <span dangerouslySetInnerHTML={{__html: sanitize(this.underlineFixer(fuzzysort.highlight(word[bestHitIndex], '<b>', '</b>')))}}></span>
                        :
                        this.processStyledText(word.obj['verbp'+(bestHitStartIndex).toString()+'e2'])
                      }
                      </List.Description>
                      :
                      null
                    }
                  </List.Content>
                </List.Item>
                })
                :
                null
                }

              {wordsList.length > 0 && this.state.homeMode === 3 ?
                wordsList.map((word) => 
                {
                return <List.Item style={{padding:'5px 1px'}} key={word.obj.url}>
                <Link to={{pathname: '/' + word.obj.url, state: { word: word.obj.url, currentCategory:this.state.currentCategory, from: this.props.location.pathname, history:[this.state.search,this.state.wordsList, this.state.Yposition, this.state.activeTabIndex] }}}>
                  <List.Content floated='right'>
                    <Icon circular style={{marginTop:'4px', color:'#8f8f8f'}} name='chevron right' />
                  </List.Content>
                </Link>
                  <List.Content>
                    <List.Header style={{fontSize:'16px',paddingBottom:'2px',fontFamily:customFontFam,lineHeight:'25px'}}>
                      <div style={{display:'flex',alignItems:'center'}}> 
                        <Link style={{color:'#306190'}} to={{pathname: '/' + word.obj.url, state: { word: word.obj.url, currentCategory:this.state.currentCategory,  from: this.props.location.pathname, history:[this.state.search,this.state.wordsList, this.state.Yposition, this.state.activeTabIndex] }}}>
                        <span style={{'paddingRight':'3px',fontWeight:'500'}}> {/* ,borderBottom:'1px solid #858585',paddingBottom:'1px', */}
                          {this.processStyledTextLink(word.obj.lexicon)}
                        </span>
                        </Link>
                        {word.obj.soundfile !== undefined ?
                          <Icon circular onClick={()=>this.repeatAudio(word.obj.soundfile)} style={{marginLeft:'10px',fontSize:'13px',color:'#8F8F8F',cursor:'pointer'}} name='volume up' />
                          :
                          null
                        }

                        {word.obj.dialect !== undefined ?
                          (word.obj.dialect.map((d,dindex)=>
                            <span style={{'marginLeft':(dindex == 0 ? '12px':'0px'),marginRight:'6px'}}>  
                              <span style={{display:'flex',marginRight:'5px'}}>
                                <TagColors homeMode={this.state.homeMode} tags={d} width={window.innerWidth} />
                              </span>
                            </span>
                          ))
                          :
                          null
                        }
                        <span style={{'marginLeft':(word.obj.dialect !== undefined ? '0px':'12px'),marginRight:'6px'}}>  
                          <span style={{display:'flex',marginRight:'5px'}}>
                            <TagColors homeMode={this.state.homeMode} tags={word.obj.pos} width={window.innerWidth} />
                          </span>
                        </span>
                      </div>
                    </List.Header>
                    <List.Description style={{fontSize:'16px',color:'#000000',fontWeight:'400',lineHeight:'23px',paddingTop:'4px'}}>
                      {this.processStyledText(word.obj.gloss.join('; '))}
                    </List.Description>
                  </List.Content>
                </List.Item>
                })
                :
                null
                }


              {displayList && this.state.homeMode === 2 ?
                wordsList.map((word, index) => {

                  let strongestHit = -100000000;
                  let bestHitIndex = -1;
                  Array.from(Array(2).keys()).reverse().map((i)=>{
                    if (word[i] !== null && word[i].score > strongestHit) {
                      strongestHit = word[i].score
                      bestHitIndex = i
                    }
                  })
                return <List.Item style={{paddingTop:10,paddingBottom:10}}>
                  <Icon circular onClick={()=>this.repeatAudio(word.obj.mp3)} style={{float:'left',marginRight:'10px',fontSize:'17px',color:(word.obj.mp3.includes('phrasebook-') ? '#6a7f56':(word.obj.mp3.includes('_') ? '#7e6464':'#5b6c85')),cursor:'pointer'}} name='volume up' />
                  <Button style={{float:'right', color:'#8F8F8F',cursor:'pointer'}} basic icon='info' onClick={()=>this.setActiveAudioIndex(word,index)} />
                  <List.Content>
                    <List.Header style={{width:'100%',display:'flex',fontFamily:customFontFam,lineHeight:'20px',fontSize:'16px',fontWeight:'500'}}>
                      {word[0] != null && bestHitIndex == 0 ?
                        <span dangerouslySetInnerHTML={{__html: sanitize(this.underlineFixer(fuzzysort.highlight(word[0], '<b>', '</b>')))}}></span>
                        :
                        this.processAudioDefinition(word.obj.t)
                      }
                    </List.Header>
                    <List.Description style={{paddingTop:'2px',fontFamily:customFontFam,lineHeight:'20px',fontSize:'16px',fontWeight:'400'}}>
                      {word[1] != null && bestHitIndex == 1 ?
                        <span dangerouslySetInnerHTML={{__html: sanitize(this.underlineFixer(fuzzysort.highlight(word[1], '<b>', '</b>')))}}></span>
                        :
                        this.processAudioDefinition(word.obj.e)
                      }
                    </List.Description>
{/*                    {index in this.state.activeAudioIndex ?
                      ('lexiconSources' in word.obj ?
                        (word.obj.lexiconSources.map((subword)=>
                          <List.Item key={subword.url}>
                                <List.Content style={{margin:15}}>
                                  <List.Header style={{fontSize:'16px',paddingBottom:'4px',fontFamily:customFontFam,lineHeight:'25px'}}>
                                        <div style={{display:'flex'}}> 
                                        <Link style={{color:'#306190'}} to={{pathname: '/' + subword.url, state: { word: subword.url, from: this.props.location.pathname, history:[this.state.search,this.state.wordsList, this.state.Yposition, this.state.activeTabIndex, this.state.activeAudioIndex] }}}>
                                        <span style={{'paddingRight':'3px',fontWeight:'500',}}>
                                        {this.processStyledTextLink(subword.lexicon)}
                                        </span>
                                        </Link>
                                        <span style={{'marginLeft':'15px',marginRight:'6px'}}>  
                                          <span style={{marginRight:'5px'}}>
                                            <TagColors homeMode={this.state.homeMode} tags={subword.pos} width={window.innerWidth} />
                                          </span>
                                        </span>
                                        </div>
                                  </List.Header>
                                  <List.Description style={{fontSize:'16px',color:'#000000cc',fontWeight:'400',lineHeight:'23px',paddingTop:'4px'}}>{this.processStyledText(subword.gloss.join('; '))}</List.Description>
                                </List.Content>
                                <div style={{margin:'10px 0px',fontSize:'16px',color:'#000000B3',marginLeft:'15px'}}>Speaker: <a href="http://ankn.uaf.edu/~tlingitverbs/examples.html" target="_blank" >Coming Soon</a></div>
                          </List.Item>                        
                        ))
                        :
                      null)
                      :
                      null
                    }     */}               
                      {index in this.state.activeAudioIndex ?
                        (word.obj.mp3.includes('phrasebook-') ?
                          <List.Item>
                                <List.Content style={{marginTop:7,padding:8}}>
                                  <List.Description style={{fontSize:'16px',color:'#000000DE',fontWeight:'400',lineHeight:'23px',marginTop:'5px'}}>
                                  <div style={{marginBottom:'12px'}}><span style={{borderBottom:'1px solid rgb(133, 133, 133)',paddingBottom:'1px'}}>{this.processStyledText('Text Source: ')}</span></div>
                                  </List.Description>

                                  <List.Description style={{display:'flex',flexWrap:'wrap',fontSize:'16px',color:'#000000DE',fontWeight:'400',lineHeight:'23px'}}>
                                  {this.spanParses(this.state.activeAudioIndex[index].keyspans,'16px',600)}
                                  </List.Description>


{/*                                  <List.Description style={{display:'flex',flexWrap:'wrap',fontSize:'16px',color:'#000000DE',fontWeight:'400',lineHeight:'23px'}}>
                                  <Link to={{pathname: '/phrasebook/'+this.state.activeAudioIndex[index].chapter}}><div style={{paddingTop:'12px',fontSize:'16px',color:'#306190'}}>Phrasebook Chapter {this.state.activeAudioIndex[index].chapter}. {this.state.activeAudioIndex[index].title}</div></Link>
                                  </List.Description>*/}

                                  <List.Description style={{fontSize:'16px',color:'#000000DE',fontWeight:'400',lineHeight:'23px',marginTop:'15px',marginBottom:'8px'}}>
                                  <div style={{marginBottom:'12px'}}><span style={{borderBottom:'1px solid rgb(133, 133, 133)',paddingBottom:'1px'}}>{this.processStyledText('Speaker: ')}</span></div>
                                  </List.Description>

                                  {this.state.activeAudioIndex[index].speakers.map((k)=>
                                      (k in speakerIDs ? 
                                      <List.Description style={{fontSize:'16px',color:'#000000DE',fontWeight:'400',lineHeight:'23px',marginTop:'15px',marginBottom:'10px'}}> 
                                      <div style={{fontSize:'16px', lineHeight:'21px', marginBottom:'15px', paddingLeft:'6px',borderLeft:'solid 1px #d3d3d3'}}>

                                      {speakerIDs[k][0].length > 0 ? <div><span style={{fontWeight:500}}>{speakerIDs[k][0]}</span></div> : null}
                                      {speakerIDs[k][2].length > 0 ? <div>Clan: <span>{speakerIDs[k][2]}</span></div> : null}
                                      {speakerIDs[k][3].length > 0 ? <div>Father's Clan: <span>{speakerIDs[k][3]}</span></div> : null}
                                      {speakerIDs[k][0].length > 0 && speakerIDs[k][0] == "Koodéik' Joseph Marks" ? <div>Father's Father: <span>{'Chookaneidi'}</span></div> : null}
                                      {speakerIDs[k][0].length > 0 && speakerIDs[k][0] == "Koodéik' Joseph Marks" ? <div>Mother's Father: <span>{'G̱aanax̱teidí'}</span></div> : null}
                                      {speakerIDs[k][4].length > 0 ? <div>Village or K̲waan: <span>{speakerIDs[k][4]}</span></div> : null}
                                      {speakerIDs[k][5].length > 0 ? <div>Clan House: <span>{speakerIDs[k][5]}</span></div> : null}

                                      </div>
                                      </List.Description>
                                      :
                                      null
                                      ))
                                  }

                                </List.Content>
                          </List.Item>
                        : 
                        (word.obj.mp3.includes('_') ? 
                          <List.Item>
                                <List.Content style={{marginTop:7,padding:8}}>
                                  <List.Description style={{fontSize:'16px',color:'#000000DE',fontWeight:'400',lineHeight:'23px',marginTop:'5px'}}>
                                  <div style={{marginBottom:'12px'}}><span style={{borderBottom:'1px solid rgb(133, 133, 133)',paddingBottom:'1px'}}>{this.processStyledText('Text Source: ')}</span></div>
                                  </List.Description>

                                  <List.Description style={{display:'flex',flexWrap:'wrap',fontSize:'16px',color:'#000000DE',fontWeight:'400',lineHeight:'23px'}}>
                                  {'bt' in this.state.activeAudioIndex[index] && this.state.activeAudioIndex[index].bt[0].length !== 0 ?
                                    this.spanParses(this.state.activeAudioIndex[index].bk,'16px',400)
                                    :
                                    null}

                                  {this.spanParses(this.state.activeAudioIndex[index].keyspans,'16px',600)}

                                  {this.spanParses(this.state.activeAudioIndex[index].ak,'16px',400)}

                                  </List.Description>

                                  <List.Description style={{fontSize:'16px',color:'#000000B3',fontWeight:'400',lineHeight:'23px',paddingTop:'8px'}}>
                                  {'be' in this.state.activeAudioIndex[index] ? 
                                    (this.state.activeAudioIndex[index].be.map((s)=><span>{s+' '}</span>))
                                    :
                                    null
                                  }
                                  <span style={{fontWeight:'bold'}}>{word.obj.e+' '}</span>
                                  {'ae' in this.state.activeAudioIndex[index] ? 
                                    (this.state.activeAudioIndex[index].ae.map((s)=><span>{s+' '}</span>))
                                    :
                                    null
                                  }                                  
                                  </List.Description>


                                  <List.Description style={{fontSize:'16px',color:'#000000DE',fontWeight:'400',lineHeight:'23px',marginTop:'15px',marginBottom:'8px'}}>
                                  <div style={{marginBottom:(word.obj.key.split('_')[0] in tlingitConversationData ? '12px' : '8px')}}><span style={{borderBottom:'1px solid rgb(133, 133, 133)',paddingBottom:'1px'}}>{this.processStyledText('Video Link and Speakers: ')}</span></div>
                                  </List.Description>

                                  {word.obj.key.split('_')[0] in tlingitConversationData ?
                                    <List.Description style={{fontSize:'16px',color:'#000000DE',fontWeight:'400',lineHeight:'23px',marginTop:'15px'}}>
                                      <a href={tlingitConversationData[word.obj.key.split('_')[0]][1]} target="_blank" style={{color:'#306190',width:'100px'}}>
                                        <Image style={{cursor:'pointer',height:'100px',borderRadius:'15px'}} src={word.obj.key.split('_')[0]+'.png'}/>
                                      </a>
                                    </List.Description>
                                    :
                                    null
                                  }

                                  <List.Description style={{fontSize:'16px',color:'#000000B3',fontWeight:'400',lineHeight:'23px',paddingTop:'10px'}}>
                                  {word.obj.key.split('_')[0] in tlingitConversationData ?
                                    <a href={tlingitConversationData[word.obj.key.split('_')[0]][1]} target="_blank" style={{cursor:'pointer',color:'#306190'}}>{`Woosh Een áyá Yoo X̲'atudli.átk — We're Talking Conversation Project — Recording #`+word.obj.key.split('_')[0]+`, Line `+word.obj.key.split('_')[1]}</a>
                                    :
                                    <a href='https://uas.alaska.edu/arts_sciences/humanities/alaska-native-studies/alaska-native-languages/tlingit-language-conversation-project/index.html' target="_blank" style={{cursor:'pointer'}}>{`Woosh Een áyá Yoo X̲'atudli.átk — We're Talking Conversation Project`}</a>
                                  }
                                  </List.Description>


                                  {word.obj.key.split('_')[0] in tlingitConversationData ?
                                    (tlingitConversationData[word.obj.key.split('_')[0]][2].map((k)=>
                                      (k in speakerIDs ? 
                                      <List.Description style={{fontSize:'16px',color:'#000000DE',fontWeight:'400',lineHeight:'23px',marginTop:'15px',marginBottom:'10px'}}> 
                                      <div style={{fontSize:'16px', lineHeight:'21px', marginBottom:'15px', paddingLeft:'6px',borderLeft:'solid 1px #d3d3d3'}}>

                                      {speakerIDs[k][0].length > 0 ? <div><span style={{fontWeight:500}}>{speakerIDs[k][0]}</span></div> : null}
                                      {speakerIDs[k][2].length > 0 ? <div>Clan: <span>{speakerIDs[k][2]}</span></div> : null}
                                      {speakerIDs[k][3].length > 0 ? <div>Father's Clan: <span>{speakerIDs[k][3]}</span></div> : null}
                                      {speakerIDs[k][4].length > 0 ? <div>Village or K̲waan: <span>{speakerIDs[k][4]}</span></div> : null}
                                      {speakerIDs[k][5].length > 0 ? <div>Clan House: <span>{speakerIDs[k][5]}</span></div> : null}

                                      </div>
                                      </List.Description>
                                      :
                                      null
                                      )
                                    ))
                                    :
                                    null
                                  }

                                </List.Content>
                          </List.Item>   
                          :
                          <List.Item>
                                <List.Content style={{marginTop:7,padding:8}}>
                                <List.Description style={{fontSize:'16px',color:'#000000DE',fontWeight:'400',lineHeight:'23px',marginTop:'5px',marginBottom:'10px'}}>
                                <div style={{marginBottom:'20px'}}><span style={{borderBottom:'1px solid rgb(133, 133, 133)',paddingBottom:'1px'}}>{this.processStyledText('Source:')}</span></div>
                                </List.Description>
                                <div style={{paddingBottom:'4px',fontSize:'16px',fontWeight:500}}><a href="http://ankn.uaf.edu/~tlingitverbs/examples.html" target="_blank" style={{color:'#306190',fontWeight:'400'}}>Tlingit Example Sentences with Audio</a></div>
                                <div style={{paddingTop:'8px',paddingBottom:'4px',fontSize:'16px',fontStyle:'italic',color:'#000000B3'}}>Speaker Information Coming Soon</div>

                                <List.Description style={{fontSize:'16px',color:'#000000DE',fontWeight:'400',lineHeight:'23px',marginTop:'5px'}}>
                                <Divider />
                                {this.state.activeAudioIndex[index].length > 1 ? <span style={{borderBottom:'1px solid rgb(133, 133, 133)',paddingBottom:'1px'}}>{this.processStyledText('Dictionary Entries: ')}</span> : <span style={{borderBottom:'1px solid rgb(133, 133, 133)',paddingBottom:'1px'}}>{this.processStyledText('Dictionary Entry: ')}</span> }
                                </List.Description>
                                </List.Content>
                                {this.state.activeAudioIndex[index].map((subword) => 
                                <List.Content style={{marginBottom:7,padding:8,paddingRight:0}}>
                                  <Link to={{pathname: '/' + subword.url, state: { word: subword.url, from: this.props.location.pathname, history:[this.state.search,this.state.wordsList, this.state.Yposition, this.state.activeTabIndex, this.state.activeAudioIndex] }}}>
                                    <List.Content floated='right'>
                                      <Icon circular style={{marginTop:'4px', color:'#8f8f8f'}} name='chevron right' />
                                    </List.Content>
                                  </Link>
                                  <List.Header style={{fontSize:'16px',paddingBottom:'4px',fontFamily:customFontFam,lineHeight:'25px'}}>
                                        <div style={{display:'flex'}}> 
                                        <Link style={{color:'#306190'}} to={{pathname: '/' + subword.url, state: { word: subword.url, from: this.props.location.pathname, history:[this.state.search,this.state.wordsList, this.state.Yposition, this.state.activeTabIndex, this.state.activeAudioIndex] }}}>
                                        <span style={{'paddingRight':'3px',fontWeight:'500',}}>
                                        {this.processStyledTextLink(subword.t.join('; '))}
                                        </span>
                                        </Link>
                                        <span style={{'marginLeft':'15px',marginRight:'6px'}}>  
                                          <span style={{marginRight:'5px'}}>
                                            <TagColors homeMode={this.state.homeMode} tags={subword.pos} width={window.innerWidth} />
                                          </span>
                                        </span>
                                        </div>
                                  </List.Header>
                                  <List.Description style={{fontSize:'16px',color:'#000000cc',fontWeight:'400',lineHeight:'23px',paddingTop:'4px'}}>{this.processStyledText(subword.e.join('; '))}</List.Description>
                                </List.Content>
                                )}
                          </List.Item>                                              
                          ))                     
                        :
                        null
                      }
                  </List.Content>
                </List.Item>
                })
              	:
              	null
              }
          </List>
          </Transition>

          <List>

        

      	<div>
{/*        {displayList ? 

          (window.innerWidth < 480 ?
          <Grid style={{marginTop:'15px',fontSize:'16px',marginBottom:'30px'}}>
              <Grid.Row>
              <Grid.Column style={{textAlign:'center'}}>
                <span style={{fontWeight:'bold'}}>Dictionary Compiled by Keri Eggleston. </span>
                <span>Website production provided by <a href="https://www.yuarcuun.com" target="_blank" >Yuarcuun Technologies</a> in partnership with the Douglas Indian Association (<a href="https://diataku.com/" target="_blank">DIA</a>). This website was made possible by ...</span>
              </Grid.Column>
              </Grid.Row>
              <Grid.Row>
              <Grid.Column style={{textAlign:'center'}}>
                Based on the 2009 publication by Sealaska Heritage Institute. For more sources, please see the print dictionary acknowledgment <a href="http://ankn.uaf.edu/~tlingitverbs/examples.html" target="_blank" >page</a>, example sentence audio recordings <a href="http://ankn.uaf.edu/~tlingitverbs/examples.html" target="_blank" >website</a>, and the Woosh Een áyá Yoo X̲'atudli.átk <a href="http://ankn.uaf.edu/~tlingitverbs/examples.html" target="_blank" >website</a>.
              </Grid.Column>
              </Grid.Row>
          </Grid>
              :
          <Grid style={{marginTop:'5px',fontSize:'16px',marginBottom:'30px'}}>
              <Grid.Row columns={2}>
              <Grid.Column>
                <span style={{fontWeight:'bold'}}>Dictionary Compiled by Keri Eggleston. </span>
                <span>Website production provided by <a href="https://www.yuarcuun.com" target="_blank" >Yuarcuun Technologies</a> in partnership with the Douglas Indian Association (<a href="https://diataku.com/" target="_blank">DIA</a>). This website was made possible by ...</span>
              </Grid.Column>
              <Grid.Column>
                Based on the 2009 publication by Sealaska Heritage Institute. For more sources, please see the print dictionary acknowledgment <a href="/tlingit-acknowledgments.pdf" target="_blank" >page</a> and the example sentence audio recordings <a href="http://ankn.uaf.edu/~tlingitverbs/examples.html" target="_blank" >website</a>.
              </Grid.Column>
              </Grid.Row>

          </Grid>
          )

         : (true ? 
            <div style={{height:'20px'}} /> 
            : 
            <div style={{marginTop:'70px',marginBottom:'20px',fontSize:'16px'}}> 
              <Link to={{pathname: '/dialogues/1', state: { from: this.props.location.pathname, history:[this.state.activeTabIndex] }}}><span style={{borderBottom:'1px solid #306190',paddingBottom:'1px',color:'#306190'}}>Click here for dialogue lessons</span>
              </Link>
            </div>
           )
          }*/}

{/*          {this.state.logoup ?
            null
            :
          <div style={{display:'flex',justifyContent:'space-evenly',alignItems:'center'}}>
            <Image style={{height:'80px',opacity:0.9,marginTop:'50px'}} src='/dialogo.png'/>
          </div>
          }*/}

{/*          {displayList  ? 
            null
            :
            <div style={{height:150,display:'flex',flexDirection:'column'}}>
              <div style={{display:'flex',alignItems:'center',paddingLeft:20,marginTop:10}}><Icon size='tiny' style={{marginRight:'10px'}} name='circle outline' /><Link to={{pathname: '/map'}}><span style={{fontSize:'16px'}}>Tlingit Place Names Map</span></Link></div>
              <div style={{display:'flex',alignItems:'center',paddingLeft:20,marginTop:10}}><Icon size='tiny' style={{marginRight:'10px'}} name='circle outline' /><Link to={{pathname: '/phrasebook'}}><span style={{fontSize:'16px'}}>Lingít X̱ʼéináx̲ Sá! "Say It In Tlingit: A Tlingit Phrasebook"</span></Link></div>
              <div style={{display:'flex',alignItems:'center',paddingLeft:20,marginTop:10}}><Icon size='tiny' style={{marginRight:'10px'}} name='circle outline' /><Link to={{pathname: '/dialogues/1'}}><span style={{fontSize:'16px'}}>Dialogue Lessons</span></Link></div>
            </div>
          }

*/}

          {emptyList ? <div style={{padding:20,textAlign:'center',fontSize:'16px',fontStyle:'italic'}}>No matches...</div>: ''}


          {!displayList && this.state.homeMode === 0 && !emptyList ? 
            <div style={{display:'flex',justifyContent:'center',padding:'30px 0px'}}>
              <div style={{fontSize:'1.2rem',color:'#666',lineHeight:1.6,maxWidth:500}}>
                <div>
                <div style={{marginBottom:10}}> Type any English or Tlingit phrase and matching dictionary entries will show automatically.</div>
                <div> Examples: </div>
                <div>
                <span onClick={()=>{this.setState({search:"yak'éi",wordsList: fuzzysort.go("yak'éi", this.props.dictionarySearchList, options1)})}} style={{color:'#4A80B5',cursor:'pointer'}}>yak'éi</span>
                <span>{', '}</span>
                <span onClick={()=>{this.setState({search:'Gunalchéesh',wordsList: fuzzysort.go('Gunalchéesh', this.props.dictionarySearchList, options1)})}} style={{color:'#4A80B5',cursor:'pointer'}}>Gunalchéesh</span>
                <span>{', '}</span>
                <span onClick={()=>{this.setState({search:'x̲áat',wordsList: fuzzysort.go('x̲áat', this.props.dictionarySearchList, options1)})}} style={{color:'#4A80B5',cursor:'pointer'}}>x̲áat</span>
                <span>{', '}</span>
                <span onClick={()=>{this.setState({search:'book',wordsList: fuzzysort.go('book', this.props.dictionarySearchList, options1)})}} style={{color:'#4A80B5',cursor:'pointer'}}>book</span>
                <span>{', '}</span>
                <span onClick={()=>{this.setState({search:'blanket',wordsList: fuzzysort.go('blanket', this.props.dictionarySearchList, options1)})}} style={{color:'#4A80B5',cursor:'pointer'}}>blanket</span>         
                <span>{', '}</span>
                <span onClick={()=>{this.setState({search:'she sees it',wordsList: fuzzysort.go('she sees it', this.props.dictionarySearchList, options1)})}} style={{color:'#4A80B5',cursor:'pointer'}}>she sees it</span>        
                </div>
                </div>


              </div>
            </div>
            :
            null
          }

          {!displayList && this.state.homeMode === 2  && !emptyList ? 
                <div style={{display:'flex',justifyContent:'center',padding:'30px 0px'}}>
                  <div style={{fontSize:'1.2rem',color:'#666',lineHeight:1.6,maxWidth:500}}>

                    <div>
                    <div style={{marginBottom:10}}> Type any English or Tlingit phrase and matching audio clips will show automatically.</div>
                    <div> Examples: </div>
                    <div>
                    <span onClick={()=>{this.setState({search:'respect',wordsList: fuzzysort.go('respect', this.returnCorrectAudioLibrary(), options3)})}} style={{color:'#4A80B5',cursor:'pointer'}}>respect</span> 
                    <span>{', '}</span>
                    <span onClick={()=>{this.setState({search:'culture',wordsList: fuzzysort.go('culture', this.returnCorrectAudioLibrary(), options3)})}} style={{color:'#4A80B5',cursor:'pointer'}}>culture</span>                           
                    <span>{', '}</span>
                    <span onClick={()=>{this.setState({search:'x̲áat',wordsList: fuzzysort.go('x̲áat', this.returnCorrectAudioLibrary(), options3)})}} style={{color:'#4A80B5',cursor:'pointer'}}>x̲áat</span>                    
                    <span>{', '}</span>
                    <span onClick={()=>{this.setState({search:"x'úx'",wordsList: fuzzysort.go("x'úx'", this.returnCorrectAudioLibrary(), options3)})}} style={{color:'#4A80B5',cursor:'pointer'}}>x'úx'</span>         
                    </div>
                    </div>

                  </div>
                </div>
            :
            null
          }


          {window.innerWidth < 480 ?
            <Grid style={{marginTop:'25px',fontSize:'16px',marginBottom:'30px'}}>
                <Grid.Row>
                <Grid.Column style={{textAlign:'center'}}>
                  <span style={{fontWeight:'bold'}}>Dictionary Compiled by Keri Eggleston. </span>
                  <span>Website production provided in partnership with the Douglas Indian Association (<a href="https://diataku.com/" target="_blank">DIA</a>). This website is still in early development.</span>
                </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                <Grid.Column style={{textAlign:'center'}}>
                Based on the 2009 publication by Sealaska Heritage Institute. For more sources, please see dictionary <a href="/tlingit-acknowledgments.pdf" target="_blank" >acknowledgments</a>, example sentence <a href="http://ankn.uaf.edu/~tlingitverbs/examples.html" target="_blank" >recordings</a>, or the Woosh Een áyá Yoo X̲'atudli.átk <a href="https://uas.alaska.edu/arts_sciences/humanities/alaska-native-studies/alaska-native-languages/tlingit-language-conversation-project/index.html" target="_blank" >project</a>.
                </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{justifyContent:'center'}}>
                <div><span style={{cursor:'pointer',color:'#666666'}} onClick={()=>{this.setState({seeMoreAcknowledgments:!this.state.seeMoreAcknowledgments})}}>See more acknowledgments<Icon  style={{color:'#929292',marginLeft:'5px'}} name={this.state.seeMoreAcknowledgments ? 'chevron up':'chevron down'} /></span></div>
                </Grid.Row>
                {this.state.seeMoreAcknowledgments ?
                  <Grid.Row style={{justifyContent:'center'}}>
                  <Grid.Column>
                  <div><b>Haa Shuká</b>, generally translated as “Our ancestors,” was chosen for this website because of the bidirectionality of the word "shuká." According to Dauenhauer and Dauenhauer in their 1990 publication, *Haa Tuwunáagu Yís: For Healing Our Spirit*, “[Shuká] refers to that which is before us or has gone before us in time—predecessors, ‘one before,’ ‘one who has gone before,’ those born ahead of us who are not behind us, as well as those unborn who wait ahead of us” (p. 19). This term encompasses the ability to look into the past and also to consider and enact changes for the future, much like this website. This website was not created alone; it was built through the collaborative efforts of people dedicated to Tlingit language and culture revitalization. Here, you will see work that has been ongoing for decades by community members, researchers, and elders and hear voices from them, like elders, great-grandparents, grandparents, parents, and ancestors passed. All of this work is for our future ancestors—yet unborn—and those currently learning the language and learning to embody the culture. The aim was to make materials and knowledge more accessible to them. This tool was created so learners of all ages can use it and create more with it.</div>
                  <div style={{marginTop:'25px'}}>Dauenhauer, Nora, and Dauenhauer, Richard. <i>Haa Tuwunáagu Yís, for Healing Our Spirit : Tlingit Oratory.</i> Seattle : Juneau: University of Washington Press; Sealaska Heritage Foundation, 1990.</div>
                  </Grid.Column>
                  </Grid.Row>
                  :
                  null
                }
            </Grid>
                :
            <Grid style={{marginTop:'20px',fontSize:'16px',marginBottom:'30px'}}>
                <Grid.Row columns={2}>
                <Grid.Column>
                  <span style={{fontWeight:'bold'}}>Dictionary Compiled by Keri Eggleston. </span>
                  <span>Website production provided in partnership with the Douglas Indian Association (<a href="https://diataku.com/" target="_blank">DIA</a>). This website is still in early development.</span>
                </Grid.Column>
                <Grid.Column>
                Based on the 2009 publication by Sealaska Heritage Institute. For more sources, please see dictionary <a href="/tlingit-acknowledgments.pdf" target="_blank" >acknowledgments</a>, example sentence <a href="http://ankn.uaf.edu/~tlingitverbs/examples.html" target="_blank" >recordings</a>, or the Woosh Een áyá Yoo X̲'atudli.átk <a href="https://uas.alaska.edu/arts_sciences/humanities/alaska-native-studies/alaska-native-languages/tlingit-language-conversation-project/index.html" target="_blank" >project</a>.
                </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{justifyContent:'center'}}>
                <div><span style={{cursor:'pointer',color:'#666666'}} onClick={()=>{this.setState({seeMoreAcknowledgments:!this.state.seeMoreAcknowledgments})}}>See more acknowledgments<Icon  style={{color:'#929292',marginLeft:'5px'}} name={this.state.seeMoreAcknowledgments ? 'chevron up':'chevron down'} /></span></div>
                </Grid.Row>
                {this.state.seeMoreAcknowledgments ?
                  <Grid.Row style={{justifyContent:'center'}}>
                  <Grid.Column>
                  <div><b>Haa Shuká</b>, generally translated as “Our ancestors,” was chosen for this website because of the bidirectionality of the word "shuká." According to Dauenhauer and Dauenhauer in their 1990 publication, *Haa Tuwunáagu Yís: For Healing Our Spirit*, “[Shuká] refers to that which is before us or has gone before us in time—predecessors, ‘one before,’ ‘one who has gone before,’ those born ahead of us who are not behind us, as well as those unborn who wait ahead of us” (p. 19). This term encompasses the ability to look into the past and also to consider and enact changes for the future, much like this website. This website was not created alone; it was built through the collaborative efforts of people dedicated to Tlingit language and culture revitalization. Here, you will see work that has been ongoing for decades by community members, researchers, and elders and hear voices from them, like elders, great-grandparents, grandparents, parents, and ancestors passed. All of this work is for our future ancestors—yet unborn—and those currently learning the language and learning to embody the culture. The aim was to make materials and knowledge more accessible to them. This tool was created so learners of all ages can use it and create more with it.</div>
                  <div style={{marginTop:'25px'}}>Dauenhauer, Nora, and Dauenhauer, Richard. <i>Haa Tuwunáagu Yís, for Healing Our Spirit : Tlingit Oratory.</i> Seattle : Juneau: University of Washington Press; Sealaska Heritage Foundation, 1990.</div>
                  </Grid.Column>
                  </Grid.Row>
                  :
                  null
                }
            </Grid>
            }



        </div>

            </List>






				</Container>
        </Grid.Column>
        </Grid.Row>
      </Grid>


			</div>
		);
	}
}
export default Home;
